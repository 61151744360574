<template>
  <div
    class="listicle-intro"
    style="display: contents"
  >
    <FormHero
      :content="listicle.hero"
      :path="path"
    />
    <DiscountCallout />
    <Listicle :content="listicle.longform" />
  </div>
</template>
<script>
import FormHero from '../components/FormHero.vue'
import Listicle from '../components/Listicle.vue'
import DiscountCallout from '../components/DiscountCallout.vue'

export default {
  components: {
    FormHero,
    Listicle,
    DiscountCallout,
  },
  props: {
    path: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      listicle: this.$quizData.listicle,
    }
  },
}
</script>
