var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"contents"}},[_c('section',{class:['split-hero-v2', _vm.content.classes]},[(_vm.content.hasCareerBox)?_c('div',{staticClass:"career-box",on:{"click":_vm.toCareers}},[_c('a',{staticClass:"career-box-link",attrs:{"href":"https://www.renewalbyandersen.com/careers"}},[_vm._v("JOB POSTINGS")]),_vm._v(" "),_c('a',{staticClass:"career-box-link",attrs:{"href":"https://www.renewalbyandersen.com/careers"}},[_vm._v("CARRERAS")])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"text-content"},[_c('div',{staticClass:"kicker-content"},[_c('img',{attrs:{"src":_vm.content.kicker_image,"alt":"stars"}}),_vm._v(" "),_c('p',{staticClass:"kicker-text",domProps:{"innerHTML":_vm._s(_vm.content.kicker_text)}})]),_vm._v(" "),_c('TitleBlock',{attrs:{"title":_vm.content.title}}),_vm._v(" "),(_vm.content.hasCareerText)?_c('div',{staticClass:"career-text"},[_vm._m(0),_vm._v(" "),_c('br'),_vm._v(" "),_vm._m(1)]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"dt-cta"},[_c('div',{staticClass:"cta-block"},[(_vm.content.textAboveCta)?_c('small',{staticClass:"text-above-cta",domProps:{"innerHTML":_vm._s(_vm.content.textAboveCta)}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"cta-wrapper"},[_c('a',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
                el: _vm.content.ctaLink,
                offset: -100,
                duration: 500,
              }),expression:"{\n                el: content.ctaLink,\n                offset: -100,\n                duration: 500,\n              }"}],staticClass:"btn",attrs:{"href":_vm.content.ctaLink,"data-trackable":_vm.content.tracking || 'quiz',"data-interaction":_vm.content.action || 'cta'},domProps:{"innerHTML":_vm._s(_vm.content.ctaText)}})])])])],1),_vm._v(" "),_c('div',{staticClass:"image-content"},[(_vm.content.dynamicImage)?_c('img',{staticClass:"dynamic-image",attrs:{"rel":"preload","src":_vm.content.dynamicImage}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"cta-block"},[(_vm.content.textAboveCta)?_c('small',{staticClass:"text-above-cta",domProps:{"innerHTML":_vm._s(_vm.content.textAboveCta)}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"cta-wrapper"},[_c('a',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
              el: _vm.content.ctaLink,
              offset: -100,
              duration: 500,
            }),expression:"{\n              el: content.ctaLink,\n              offset: -100,\n              duration: 500,\n            }"}],staticClass:"btn",attrs:{"href":_vm.content.ctaLink,"data-trackable":_vm.content.tracking || 'quiz',"data-interaction":_vm.content.action || 'cta'},domProps:{"innerHTML":_vm._s(_vm.content.ctaText)}})])])])]),_vm._v(" "),(_vm.showDiscount && _vm.discountTicker)?_c('DiscountTicker',{attrs:{"title":_vm.content.discount_title,"disclaimerNotice":_vm.content.disclaimerNotice}}):_vm._e(),_vm._v(" "),(_vm.showDiscount && _vm.discountCallout)?_c('DiscountCallout'):_vm._e(),_vm._v(" "),(_vm.showDiscount && (_vm.discountCarousel || _vm.discountCarouselv2))?_c('DiscountCarousel',{attrs:{"isV2":_vm.discountCarouselv2}}):_vm._e(),_vm._v(" "),(_vm.showDiscount && _vm.discountCards)?_c('DiscountCards'):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('b',[_vm._v("Looking for a Job?")]),_vm._v(" Check out our careers page "),_c('a',{staticClass:"career-text-link",attrs:{"href":"https://www.renewalbyandersen.com/careers"}},[_c('b',[_vm._v("HERE")])]),_vm._v(".\n        ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('b',[_vm._v("En busca de trabajo?")]),_vm._v(" Visita nuestra pagina de carreras "),_c('a',{staticClass:"career-text-link",attrs:{"href":"https://www.renewalbyandersen.com/careers"}},[_c('b',[_vm._v("AQUÍ")])]),_vm._v(".\n        ")])
}]

export { render, staticRenderFns }