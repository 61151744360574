<template>
  <div
    class="dyanmic-hero-intro"
    style="display: contents"
  >
    <SimpleHero
      :isCorpPage="isCorpPage"
      :isEslerPage="isEslerPage"
      :isMoorePage="isMoorePage"
      :path="path"
    />
    <JdpowerAward
      v-if="showJdpowerAward"
      :class="isStickyJdPowerPage ? 'sticky' : ''"
      :isHideStickyJdPowerAfterQuiz="isHideStickyJdPowerAfterQuiz"
    />
  </div>
</template>

<script>
import SimpleHero from '../components/SimpleHero.vue'

const JdpowerAward = () => import('../components/JdpowerAward.vue')

export default {
  components: {
    JdpowerAward,
    SimpleHero,
  },
  props: {
    path: {
      type: String,
      default: '',
    },
  },
  computed: {
    isEslerPage() {
      return this.path.includes('esler')
    },
    isCorpPage() {
      return this.path.includes('corp')
    },
    isMoorePage() {
      return this.path.includes('moore')
    },
    showJdpowerAward() {
      return this.$quizData.showJdpowerAward
    },
    isStickyJdPowerPage() {
      // const showUrls = [
      //   '/esler/arizona',
      //   '/esler/sne',
      //   '/esler/philly',
      //   '/esler/gen',
      // ]
      //
      // return showUrls.includes(this.path)

      return false
    },
    isHideStickyJdPowerAfterQuiz() {
      // const urls = [
      //   '/esler/arizona',
      // ]
      //
      // return urls.includes(this.path)

      return false
    },
  },
}
</script>
