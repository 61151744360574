<template>
  <div style="display: contents;">
    <SplitHero
      :content="content.hero"
    />

    <DiscountTicker
      v-if="showDiscountTicker"
      :title="content.discount_title"
      :disclaimerNotice="content.disclaimerNotice"
    />
  </div>
</template>

<script>
import SplitHero from '../components/SplitHero.vue'
import DiscountTicker from '../components/DiscountTicker.vue'

export default {
  components: {
    SplitHero,
    DiscountTicker,
  },
  data() {
    return {
      content: this.$quizData.splitHeroIntro,
      showDiscountTicker: !!this.$quizData.discount,
    }
  },
}
</script>
