<template>
  <section :class="`page-section banner-section${content.classes ? ` ${content.classes}` : ''}`">
    <div class="container">
      <h3 v-html="content.text" />
    </div>
  </section>
</template>

<script>
export default {
  props: {
    content: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
