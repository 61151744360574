<template>
  <div style="display: contents;">
    <section
      :class="['split-hero-v3', content.classes]"
    >
      <ResultsForm
        :heroFormTitle="content.heroFormTitle"
        :formTop="this.$quizData.formTop"
        :path="path"
        :noQuiz="true"
      />
      <div class="image-content">
        <img
          v-if="content.dynamicImage"
          class="dynamic-image"
          rel="preload"
          :src="content.dynamicImage"
        >
        <div class="cta-block">
          <small
            v-if="content.textAboveCta"
            class="text-above-cta"
            v-html="content.textAboveCta"
          />
          <div class="cta-wrapper">
            <a
              v-scroll-to="{
                el: content.ctaLink,
                offset: -100,
                duration: 500,
              }"
              :href="content.ctaLink"
              :data-trackable="content.tracking || 'quiz'"
              :data-interaction="content.action || 'cta'"
              class="btn"
              v-html="content.ctaText"
            />
          </div>
        </div>
      </div>
    </section>

    <DiscountTicker
      v-if="showDiscount && discountTicker"
      :title="content.discount_title"
      :disclaimerNotice="content.disclaimerNotice"
    />
    <DiscountCallout v-if="showDiscount && discountCallout" />
    <DiscountCarousel
      v-if="showDiscount && (discountCarousel || discountCarouselv2)"
      :isV2="discountCarouselv2"
    />
    <DiscountCards v-if="showDiscount && discountCards" />
  </div>
</template>

<script>
import TitleBlock from '../components/TitleBlock.vue'
import DiscountTicker from '../components/DiscountTicker.vue'
import DiscountCallout from '../components/DiscountCallout.vue'
import DiscountCarousel from '../components/DiscountCarousel.vue'
import DiscountCards from '../components/DiscountCards.vue'
import ResultsForm from '../components/ResultsForm.vue'

export default {
  components: {
    TitleBlock,
    DiscountTicker,
    DiscountCallout,
    DiscountCarousel,
    DiscountCards,
    ResultsForm,
  },
  props: {
    path: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      content: this.$quizData.splitHeroIntrov3,
      phoneUrl: this.$tvData.phone_number || gon.phone_number || '000-000-0000',
      showDiscount: !!this.$quizData.discount,
      discountCallout: !!this.$quizData.showDiscountCallout,
      discountTicker: !!this.$quizData.showDiscountTicker,
      discountCarousel: !!this.$quizData.showDiscountCarousel,
      discountCarouselv2: !!this.$quizData.showDiscountCarouselv2,
      discountCards: !!this.$quizData.showDiscountCards,
    }
  },
  mounted() {
    const booking = document.getElementsByClassName('booking-cta')

    booking.forEach(btn => {
      btn.addEventListener('click', () => {
        _paq.push(['trackEvent', 'general-cta', 'phone-call'])
      })
    })

    for (let i = 0; i < booking.length; i++) {
      booking[i].href = `tel:${this.phoneUrl}`
      booking[i].innerHTML += `<span style='display: none;'>${this.phoneUrl}</span>`
    }
  },
}
</script>
