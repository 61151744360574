<template>
  <div class="above-quiz-title">
    <h2
      class="title"
      v-html="title"
    />
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: this.$quizData.aboveQuizTitle,
    }
  },
}
</script>
