export default {
  QUIZDATA: state => state.quizData,
  NUMBER_OF_QUESTIONS: (state, getters) => {
    if (getters.QUIZDATA.sequences) {
      return getters.QUIZDATA.sequences.length
    }
    return 0
  },
  ONE_PAGE: state => state.quizData.one_page || false,
  ONE_PAGE_ERROR_MESSAGE: state => state.quizData.one_page_error_message || 'Please Complete All The Questions',
  ONE_PAGE_VALID: state => state.onePageValid,
  GAMIFIED: state => state.quizData.gamified || false,
  TOP_SLOT: state => state.quizData.top_slot || false,
  BOTTOM_SLOT: state => state.quizData.bottom_slot || false,
  ADVANCE_QUIZ_DATA: state => state.advanceQuiz,
  QUIZ_STARTED: state => state.quizStarted,
  QUIZ_COMPLETED: state => state.quizCompleted,
  LOADED: state => state.loaded,
  HIDE_LOADER: state => state.quizData.hide_loader || false,
  SKIP_QUIZ_BUTTON: state => state.quizData.intro.skip_quiz_button,
  PREVENT_DEFAULT_SUBMIT: state => state.quizData.prevent_default_submit || false,
  HERO_DATA: state => state.heroData,
  APPOINTMENT_DATA: state => state.appointmentData,
}
