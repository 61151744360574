<template>
  <LoadingGif :show="true" />
</template>

<style scoped>
  .form-error {
    margin-top: 8px;
  }

  .btn.disabled {
    background: #c4c4c4;
    cursor: not-allowed;
  }
</style>

<script>
import axios from 'axios'
import LoadingGif from './LoadingGif.vue'

export default {
  components: {
    LoadingGif,
  },
  props: {
    path: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      formspreeID: this.$quizData.formspree_id,
      isCanada: !!(
        this.path.includes('toronto')
          || this.path.includes('corp/national/ca')
          || this.path.includes('canada')
      ),
      phoneNumber: gon.phone_number || '000-000-0000',
      form: {
        email: '',
        firstName: '',
        lastName: '',
        postalCode: '',
        zipcode: '',
        phoneNumber: '',
      },
      data: {},
      userResponses: '',
      formError: false,
      errorMessage: '',
      isSubmitting: false,
      editedQuizResults: [],
      onlineScheduling: false,
      submissionId: null,
      hatchDivision: null,
      apiResponse: []
    }
  },
  computed: {
    isDisabled() {
      return this.isSubmitting /* || this.$v.$error || this.$v.$anyError */
    },
    apiUrl() {
      if (
        window.location.hostname === 'localhost'
          || this.path.includes('_stg')
      ) {
        return 'https://fc2-stg.thesistestingapps.com/eaaa3934/lead-form-1'
      }
      return 'https://fc2.thesistestingapps.com/eaaa3934/lead-form-1'
    },
    quizResults() {
      return this.$store.getters.RESULTS
    },
    rbaBreakdown() {
      // consolidated URLs
      const consolidatedUrls = [
        '/moore/gen',
        '/moore/midwest/gen',
        'tx-ok/search/doors',
        'tx-ok/search/windows',
        'doors',
        'windows',
      ]
      if (this.$quizData.rba_breakdown) return this.$quizData.rba_breakdown
      if (this.path.includes('/101')) return 'Google Brand'
      if (this.path.includes('/102')) return 'Google NB'
      if (this.path.includes('/201')) return 'Bing Brand'
      if (this.path.includes('/202')) return 'Bing NB'
      if (this.$quizData.is_tv_lp) return this.$tvData.breakdown
      if (
        this.path.includes('esler/gen/nextdoor')
          || this.path.includes('pittsburgh/nextdoor')
          || this.path.includes('corp/gen/nextdoor')
      ) {
        return 'Thesis Nextdoor'
      }
      if (this.path.includes('/tt') || this.path.includes('/tiktok') || this.path.includes('/oregon/social')) {
        return 'Thesis TikTok'
      }
      if (this.path.includes('/pch')) return 'PCH Email'
      if (this.path.includes('corp/national/nextdoor')) {
        return 'national_nextdoor'
      }
      if (this.path.includes('nextdoor')) return 'Next Door'
      if (this.path.includes('/corp/national/t')) return 'Twitter'
      if (this.path.includes('/esler/video')) return 'ConvergeCTV'
      if (this.path.includes('/esler/audio')) return 'ConvergeStreamingAudio'
      if (this.path.includes('/moore/comp')) return 'Calusa Marketing'
      if (this.path.includes('podcasts')) return 'Podcast Within'
      if (this.path.includes('/email')) return 'Email Test'
      if (this.path.includes('/discovery')) return 'ThesisGoogleDiscovery'
      if (this.path.includes('/esler/search')) return 'ThesisPPC'
      if (this.path.includes('/esler/display/windows')) {
        return 'ThesisDisplay'
      }
      if (this.path.includes('/esler/gen/audio/1')) return 'Barrington Terrestrial - Landing Page'
      if (this.path.includes('/audio/1')) return 'Barrington Terrestrial'
      if (this.path.includes('/audio/2') || this.path.includes('/audio/3')) return 'Barrington Digital'
      if (this.path.includes('/scn')) return 'ThesisNativeContent'
      if (
        (this.isEsler
            && this.path.includes('/gen')
            && !this.path.includes('taboola'))
          || consolidatedUrls.includes(this.path)
          || this.path.includes('/westchester')
      ) {
        return 'Hubble Facebook Consolidated'
      }

      if (
        this.path.includes('moore/midwest/gen')
          || this.path.includes('moore/metro/gen')
      ) {
        return 'Hubble Facebook Consolidated'
      }
      if (
        this.path.includes('moore/midwest/ded')
          || this.path.includes('moore/metro/ded')
      ) {
        return 'Hubble Facebook'
      }
      if (
        this.path.includes('/mlb')
      ) {
        return 'MLB Email'
      }

      const urlParams = new URLSearchParams(window.location.search)
      const isEslerSearch = this.path.includes('/esler/search')

      if (isEslerSearch && urlParams.get('utm_medium')) {
        if (urlParams.get('utm_medium').toLowerCase() === 'thesis-ppc') {
          return 'ThesisPPC'
        }
      }

      if (urlParams.get('utm_source')) {
        if (urlParams.get('utm_source').toLowerCase() === 'pinterest') {
          return 'Hubble Pinterest'
        }
        if (urlParams.get('utm_source').toLowerCase() === 'google') {
          return 'Hubble Google'
        }
        if (
          urlParams.get('utm_source').toLowerCase() === 'youtube'
            && this.isEsler
        ) {
          return 'Thesis Youtube'
        }
      }

      return 'Hubble Facebook'
    },
    RbASource() {
      if (this.$quizData.rba_source) return this.$quizData.rba_source
      if (this.$quizData.is_tv_lp) return this.$tvData.source
      if (this.path.includes('corp/national/nextdoor')) return 'social_media'
      if (this.path.includes('podcasts')) {
        return 'Radio'
      }
      if (
        this.path.includes('/esler/video')
          || this.path.includes('/esler/audio')
          || this.path.includes('/moore/comp')
          || this.path.includes('/pch')
          || this.path.includes('/mlb')
          || this.rbaBreakdown === 'Thesis Youtube'
      ) {
        return 'Digital Media'
      }
      if (
          this.path.includes('/discovery')
          || this.path.includes('/101')
          || this.path.includes('/102')
          || this.path.includes('/201')
          || this.path.includes('/202')
      ) {
        return 'PPC Web'
      }
      if (this.path.includes('/email')) {
        return 'Direct Email'
      }
      if (this.path.includes('search') && this.rbaBreakdown === 'ThesisPPC') {
        return 'PPC Web'
      }
      if (this.path.includes('/esler/display/windows')) return 'PPC Web'
      if (this.path.includes('/audio')) return 'Radio'
      if (this.path.includes('/oregon/social')) {
        return 'Social Media'
      }
      return 'Social Media'
    },
    isEsler() {
      return !!this.path.includes('esler')
    },
    isMoore() {
      return !!this.path.includes('moore')
    },
  },
  mounted() {
    if (window.location.href.includes('localhost') || window.location.href.includes('_stg')) {
      console.log(`Source:  ${this.$quizData.rba_source}`)
      console.log(`BREAKDOWN:  ${this.$quizData.rba_breakdown}`)
    }
    this.submitForm()
  },
  methods: {
    submitForm() {
      this.formError = false
      if (this.isCanada) {
        this.form.postalCode = this.form.postalCode.toUpperCase()
      }

      const blockedEmails = [
        'sdfwedw@gmail.com',
        'tornanirkhalighor@gmail.com',
        'bodixos323@wanbeiz.com',
        'karen-xdhub69@uma3.be'
      ]

      if (blockedEmails.includes(this.form.email)) return

      this.isSubmitting = true
      this.createDataObject()
      this.sortResultsAndAddParams()
      this.sendDataToApi().catch((e) => console.log(e))

      if (this.path.includes('nextdoor') || this.path.includes('corp')) {
        if (typeof ndp !== 'undefined') {
          ndp('track', 'CONVERSION')
        }
      }
      if (this.path.includes('/tt') || this.path.includes('/tiktok')) {
        if (typeof ttq !== 'undefined') {
          ttq.identify({
            email: this.form.email,
            phone_number: `+1${this.form.phoneNumber}`,
          })

          ttq.track('SubmitForm')
        }
      }
      if (typeof gtag_report_conversion !== 'undefined') {
        gtag_report_conversion()
      }
      if (typeof rdt !== 'undefined') {
        if (this.path.includes('/oregon/social')) {
          rdt('track', 'Lead', {
            currency: 'USD',
            transactionId: '12345678',
            value: 100,
          })
        }
      }
      _paq.push(['trackEvent', 'general', 'form-submit'])
    },
    createDataObject() {
      // Gets contact data from the quiz
      this.data = {
        EmailAddress:
            this.quizResults[
              this.quizResults.findIndex(r => !!r.content['Email Address*'])
            ].content['Email Address*'],
        FirstName:
            this.quizResults[
              this.quizResults.findIndex(r => !!r.content['First Name*'])
            ].content['First Name*'],
        LastName:
            this.quizResults[
              this.quizResults.findIndex(r => !!r.content['Last Name*'])
            ].content['Last Name*'],
        Zipcode:
            this.quizResults[
              this.quizResults.findIndex(r => !!r.content['Zip Code'])
            ].content['Zip Code'],
        'Phone Number':
            this.quizResults[
              this.quizResults.findIndex(r => !!r.content['Phone Number*'])
            ].content['Phone Number*'],
        'Form Type': 2,
        Sender: 'Thesis',
        RbASource: this.RbASource,
        RbABreakdown: this.rbaBreakdown,
        CallingRights: 'Y',
        rba_1: '',
        rba_2: '',
        rba_3: '',
        rba_4: '',
        rba_5: '',
      }

      const gclid = new URLSearchParams(window.location.search).get('gclid')
      if (gclid) this.data.rba_6 = `${gclid}`
      this.quizResults.forEach(question => {
        if (
          typeof question.content !== 'object'
            && question.content !== null
        ) {
          this.editedQuizResults.push(question)
        }
      })
    },
    sortResultsAndAddParams() {
      let run = false
      const resultsArray = []
      const quizAnswers = this.editedQuizResults

      // Visual quiz needs spans removed from quiz answer html
      // otherwise rba api will send error on submit
      const isVisualQuiz = this.$quizData.sanitize_quiz_answers
      if (!run) {
        quizAnswers.forEach(result => {
          if (result === null) return
          const question = result.label
            .split('?')[0]
            .replace('&nbsp;', ' ')
            .replace("<br class='d-sm-up'>", '')
          if (Array.isArray(result.content)) {
            let answers = ''

            if (isVisualQuiz) {
              // Visual quiz multi-choice
              result.content.forEach(word => {
                // Split on end of image span
                const split = word.selection.split('</span>')

                // Add what's after span tag or regular content
                answers += `${split[1] || split[0]}, `
              })
            } else {
              result.content.forEach(word => {
                answers += `${word.selection}, `
              })
            }

            resultsArray.push(` ${question}?: ${answers.slice(0, -2)}`)
          } else if (isVisualQuiz) {
            // Visual quiz non-multi-choice
            const content = result.content.split('</span>')
            resultsArray.push(` ${question}?: ${content[1] || content[0]}`)
          } else {
            resultsArray.push(` ${question}?: ${result.content}`)
          }
        })
        this.data.WindowsProblems = resultsArray.join(' |')
        this.addUTMparams()
        run = true
      }
    },
    removeStgFromPathname(pathname) {
      if (pathname.includes('/_stg/')) {
        // Split the pathname into an array of path segments
        const pathSegments = pathname.split('/')
        // Find the index of the '_stg' segment
        const stgIndex = pathSegments.findIndex(segment => segment === '_stg')
        // Remove the '_stg' segment and its following segment (the digits)
        pathSegments.splice(stgIndex, 2)
        // Join the path segments back into a string
        pathname = pathSegments.join('/')
      }
      return pathname
    },
    isOneSourcePage() {
      const oneSourcePaths = [
        '/esler/social',
        '/moore/social',
      ]
      // return false if path is not in paths
      if (!oneSourcePaths.includes(this.removeStgFromPathname(window.location.pathname))) return false
      const queryParams = window.location.search.slice(1).split('&')
      const paramMap = new Map()
      queryParams.forEach(param => {
        const [key, value] = param.split('=')
        paramMap.set(key, value)
      })
      // check is params are present for onesource form submit
      if (
        paramMap.get('rba_channel') === 'ott'
          && ((paramMap.get('rba_market') === 'esler' || paramMap.get('rba_market') === 'moore'))
      ) {
        return true
      }
      return false
    },
    createSchedulingData() {
      const schedulingData = new URLSearchParams()
      schedulingData.append('FirstName', this.form.firstName)
      schedulingData.append('LastName', this.form.lastName)
      schedulingData.append('EmailAddress', this.form.email)
      schedulingData.append('PhoneNumber', this.form.phoneNumber)
      schedulingData.append('Zipcode', this.form.zipcode || this.form.postalCode)
      schedulingData.append('Sender', 'Thesis-BMG')
      schedulingData.append('RbASource', this.rbaSource)
      schedulingData.append('RbABreakdown', this.rbaBreakdown)
      schedulingData.append('FormType', 2)
      schedulingData.append('OnlineSchedulingSubmission', 'primary')

      schedulingData.append('rba_1', this.data.rba_1)
      schedulingData.append('rba_2', this.data.rba_2)
      schedulingData.append('rba_3', this.data.rba_3)
      schedulingData.append('rba_4', this.data.rba_4)
      schedulingData.append('rba_5', this.data.rba_5)
      if (this.data.rba_6) { schedulingData.append('rba_6', this.data.rba_6) }

      return schedulingData
    },
    bmgImpactSubmission(data) {
      return fetch('https://ambassador.thesistestingapps.com/rba/impact', {
        method: 'POST',
        body: data,
      })
    },
    oneSourceSubmission(data) {
      return fetch('https://ambassador.thesistestingapps.com/rba/one-source', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
    },
    async sendDataToApi() {
      let url = ''
      let headers = {}
      
      if (this.path.includes('_stg')) {
        url = 'https://test.renewalbyandersen.com/api/sitecore/featureforms/submitform'
        headers = {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: '611EB4FF-92F7-4883-A757-EC8572BCF174',
        }
      } else {
        url = 'https://www.renewalbyandersen.com/api/sitecore/featureforms/submitform'
        headers = {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: 'F393C372-28C2-401C-BECE-07C3192BF5E9',
        }
      }

      const schedulingData = this.createSchedulingData()

      let enabledRes = await fetch(url, { method: 'POST', headers, body: schedulingData })
      if (!enabledRes.ok) {
        this.errorMessage = this.isCanada
            ? 'Renewal by Andersen is unavailable in your area.'
            : e.message.split(':').slice(-1)[0].trim()
        this.formError = true
        this.isSubmitting = false
        window.rba.tracking.contactFormError(this.errorMessage)
        return
      }

      let enabledJSON = await enabledRes.json()
      this.apiResponse.push(enabledJSON)

      let impactRes = await this.bmgImpactSubmission(schedulingData)
      if (!impactRes.ok) console.log(impactRes.statusText)

      this.onlineScheduling = enabledJSON.isOnlineSchedulingEligible || false
      this.submissionId = enabledJSON.submissionId || null
      const resultId = enabledJSON.resultId || null

      let hatchDivision = null
      if (this.path.includes('moore')) hatchDivision = 'moore'
      else if (this.path.includes('/esler/search')) hatchDivision = 'esler_search'
      else if (this.path.includes('/esler/social') || this.path.includes('/esler/tx-ok')) hatchDivision = 'esler_social'
      else if (this.path.includes('/eastern-ny')) hatchDivision = 'eastern_ny'
      else if (this.path.includes('rbawa')) hatchDivision = 'rbawa'

      this.hatchDivision = hatchDivision
      
      if (this.onlineScheduling === false) {
        if (this.hatchDivision != null) {
          const division = this.hatchDivision

          let hatchRes = await fetch('https://ambassador.thesistestingapps.com/rba/hatch', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ ...this.data, division })
          })
          if (!hatchRes.ok) {
            const errorMessage = 'Hatch API integration failed to post the appropriate data'
            window.rba.tracking.contactFormError(errorMessage)
          }
        }
      }

      if (this.isOneSourcePage()) {
        const oneSourceData = {
          name: `${this.data.FirstName} ${this.data.LastName}`,
          callerId: this.data['Phone Number'],
          email: this.data.EmailAddress,
        }
        await this.oneSourceSubmission(oneSourceData)
      }

      await this.formSubmitComplete(resultId).catch((e) => console.log(e))
    },
    getDivision() {
      if (this.path.includes('esler')) return 'esler'
      if (this.path.includes('moore')) return 'moore'
      if (this.path.includes('corp')) return 'corp'
      if (this.path.includes('oregon')) return 'oregon'
      if (this.path.includes('rbawa')) return 'rbawa'
      if (this.path.includes('eastern_ny')) return 'eastern_ny'
      return 'esler'
    },
    redirectToThankYouPage(uuid) {
      if (this.onlineScheduling && this.submissionId !== null) {
        window.location = `https://offers.renewalbyandersen.com/online-scheduling/?submission_id=${this.submissionId}&zipcode=${this.isCanada ? this.form.postalCode : this.form.zipcode}&division=${this.getDivision()}${uuid ? `&uuid=${uuid}` : ''}${this.hatchDivision ? `&hatchDivision=${this.hatchDivision}` : ''}${this.path.includes('esler/search') ? '&channelAuto=true' : ''}${this.isCanada ? '&canada=true' : ''}`
        return
      }

      const searchParams = new URLSearchParams(window.location.search)
      const params = {}
      for (const p of searchParams) {
        params[p[0]] = p[1]
      }
      if (this.path.includes('corp')) {
        //
        if (this.path.includes('boston') || (params.rba_market == 'boston')) {
          window.location.href = '/corp/thank-you-boston'
        } else if (this.path.includes('chicago') || (params.rba_market == 'chicago')) {
          window.location.href = '/corp/thank-you-chicago'
        } else if (this.path.includes('cleveland') || (params.rba_market == 'cleveland')) {
          window.location.href = '/corp/thank-you-cleveland'
        } else if (this.path.includes('detroit') || (params.rba_market == 'detroit')) {
          window.location.href = '/corp/thank-you-detroit'
        } else if (this.path.includes('pittsburgh') || (params.rba_market == 'pittsburgh')) {
          window.location.href = '/corp/thank-you-pittsburgh'
        } else if (this.path.includes('capital') || (params.rba_market == 'capital')) {
          window.location.href = '/corp/thank-you-capital'
        } else if (this.path.includes('la') || (params.rba_market == 'la')) {
          window.location.href = '/corp/thank-you-la'
        } else if (this.path.includes('orange') || (params.rba_market == 'orange')) {
          window.location.href = '/corp/thank-you-orange'
        } else if (this.path.includes('twin') || (params.rba_market == 'twin')) {
          window.location.href = '/corp/thank-you-twin'
        } else if (this.path.includes('national/ca') || (params.rba_market == 'canada')) {
          window.location.href = '/corp/thank-you-national-ca'
        } else if (this.path.includes('national') || (params.rba_market == 'national') || (params.rba_channel == 'twitter' && params.rba_market == 'coro')) {
          window.location.href = '/corp/thank-you-national'
        } else if (this.path.includes('michigan') || (params.rba_market == 'michigan')) {
          window.location.href = '/corp/thank-you-michigan'
        } else if (this.path.includes('gen') || (params.rba_market == 'coro')) {
          window.location.href = '/corp/thank-you-gen'
        } else {
          window.location.href = '/corp/thank-you-gen'
        }
        //
      } else if (this.path.includes('moore')) {
        //
        if (this.path.includes('midwest') || params.rba_market == 'mw' || (params.rba_market == 'midwest')) {
          window.location.href = '/moore/thank-you-midwest'
        } else if (this.path.includes('sf') || (params.rba_market == 'sf')) {
          window.location.href = '/moore/thank-you-sf'
        } else if (this.path.includes('toronto') || (params.rba_market == 'toronto')) {
          window.location.href = '/moore/thank-you-toronto'
        } else if (this.path.includes('/atl') || (params.rba_market == 'atl')) {
          window.location.href = '/moore/thank-you-atl'
        } else if (this.path.includes('/pch') || (params.rba_market == 'pch')) {
          window.location.href = '/moore/thank-you-gen?pch=true'
        } else window.location.href = '/moore/thank-you-gen'
        //
      } else if (this.path.includes('podcasts')) {
        window.location.href = '/thank-you'
      } else {
        window.location.href = '/esler/thank-you'
      }
    },
    addUTMparams() {
      const urlParams = new URLSearchParams(window.location.search)
      // eslint-disable-next-line no-restricted-syntax
      for (const key of urlParams.keys()) {
        switch (key) {
          case 'utm_campaign':
            this.data.rba_1 = urlParams.get('utm_campaign')
            break
          case 'utm_source':
            this.data.rba_2 = urlParams.get('utm_source')
            break
          case 'utm_medium':
            this.data.rba_3 = urlParams.get('utm_medium')
            break
          case 'utm_content':
            this.data.rba_4 = urlParams.get('utm_content')
            break
          case 'utm_term':
            this.data.rba_5 = urlParams.get('utm_term')
            break
          default:
            // eslint-disable-next-line no-console
            console.log('unpermitted param')
        }
      }
    },
    postToFormSpree() {
      return axios({
        url: `https://formspree.io/${this.formspreeID}`,
        method: 'POST',
        headers: {
          Accept: 'application/json',
        },
        data: this.data,
      })
    },
    createDBRecord() {
      return fetch('https://ambassador.thesistestingapps.com/rba/leadData', {
        method: 'POST',
        body: JSON.stringify({...this.data}),
      })
    },
    async formSubmitComplete(resultId) {
      window.rba.tracking.contactFormSuccess(this.form, resultId)
      if (typeof window.uet_report_conversion !== 'undefined') {
        window.uet_report_conversion()
      }
      if (this.formspreeID) {
        await this.postToFormSpree().catch((e) => console.log(e))
      }

      let dbRes = await this.createDBRecord()
      let dbJSON = await dbRes.json()

      await fetch('https://ambassador.thesistestingapps.com/rba/apiData', {
        method: 'POST',
        body: JSON.stringify({...this.apiResponse, uuid: dbJSON.uuid}),
      }).catch((e) => console.log(e));

      setTimeout(() => this.redirectToThankYouPage(dbJSON.uuid), 4000)
    }
  },
}
</script>
