<template>
  <Loading
    :active="show"
    :canCancel="false"
    :isFullPage="true"
    loader="dots"
    backgroundColor="#fff"
    :opacity="1"
  >
    <template #before>
      <header class="site-header loader-header">
        <div class="container">
          <a
            class="header-logo-link"
            href="#"
          >
            <img
              class="header-logo"
              :src="logo"
              alt="Logo Image"
            >
          </a>
        </div>
      </header>
    </template>
    <template #default>
      <div class="loading-gif-container">
        <img
          :src="loading"
          alt="Loading Icon"
        >
      </div>
    </template>
    <template #after>
      <div class="loader-text-container">
        <p class="loader-text">
          Measuring up to your expectations...
        </p>
      </div>
    </template>
  </Loading>
</template>

<script>
import Loading from 'vue-loading-overlay'
import logo from '../images/logo-green.svg'
import loading from '../images/loading.gif'

export default {
  components: {
    Loading,
  },
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      logo,
      loading,
    }
  },
}
</script>
