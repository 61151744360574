<template >
    <div
      id="discount-banner"
      :class="[
        'discount-banner',
      ]"
    >  
      <div class="hero-carousel-wrapper">
        <div v-if="formattedFinance">
            <h4
                class="title finance-lang"
                v-html="formattedFinance"
            />
        </div>
        <div
          v-for="(offer, i) in offers"
          :key="`card-${i}`"
          class="card"
        >
          <img
            v-if="offer.icon"
            class="icon"
            :src="importImage(offer.icon)"
            alt="Discount Icon"
          >
          <h3
            v-if="offer.headline"
            class="headline"
            v-html="offer.headline"
          />
          <h4
            v-if="offer.offer"
            class="title"
            v-html="offer.offer"
          />
          <h5
            v-if="offer.payment"
            class="payment"
            v-html="offer.payment"
          />
          <h6
            v-if="offer.expire_text"
            class="expire-text"
            v-html="offer.expire_text"
            :class="{'light-text': offer.expire_text.toLowerCase()[0]=== '*'}"
          />
          <p
          v-if="offer.disclaimer && !offer.disclaimer.toLowerCase().includes('swipe')"
          class="disclaimer"
          v-html="offer.disclaimer"
        />
        </div>
      </div> 
        <div
            class="arrows-container"
            >
            <img
                class="left-arrow"
                :src="arrow"
            >
            <img
                class="right-arrow"
                :src="arrow"
            >
        </div>
    </div>
  </template>
  
  <script>
  import arrow from '../images/chevron-right.svg'
  import data from '../data/discountCarousel.json'
  
  export default {
    props: {
      isV2: {
        type: Boolean,
        default: false,
      },
      isV3: {
        type: Boolean,
        default: false,
      },
    },
    data() {
    const discountSection = this.$quizData.discountSection
    const offerKey = this.$quizData.discount
    const dynamic_offer = this.$quizData.dynamic_discount
    let formattedFinance = discountSection.stapleTitle
    if(discountSection.stapleTitle){
        formattedFinance = discountSection.stapleTitle.split('<br>')
        formattedFinance[formattedFinance.length-1] = `<span class="finance-disclaimer">${formattedFinance[formattedFinance.length-1] || ''}</span>`
        // formattedFinance[1] = `<span class="finance-disclaimer">${formattedFinance[1] || ''}</span>`
        formattedFinance = formattedFinance.join(' ')
    }
    //Checks for dynamic_offer, then offerKey, then defaults otherwise
    //Will probably be able to remove check for offerKey in the future but this way we can still hardcode offers if needed.
    //let offers = dynamic_offer.length() !== 0 ? dynamic_offer : offerKey  ? data[offerKey] : data.default
    let offer = data.default
    if(dynamic_offer){
      offer = dynamic_offer
    }
    else if(offerKey){
      offer = data[offerKey]
    }
      let offers = offer
      if(this.$quizData.dynamic_first_card){
        offers.unshift(
            this.$quizData.dynamic_first_card[0]
        )
      } else {
        offers.unshift({
          payment: 'No interest, no money down, no payments for 1 year!',
        })
      }
      let width = window.innerWidth
      return {
        offersLength: offers.length,
        offers,
        arrow,
        currentIndex: 0,
        discountSection,
        formattedFinance,
        isDesktop: width >= 1024
      }
    },
    mounted() {
        $('.hero-carousel-wrapper').slick({
          mobileFirst: true,
          arrows: true,
          dots: false,
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 6500,
          prevArrow: $('.arrows-container .left-arrow'),
          nextArrow: $('.arrows-container .right-arrow'),
        })
        window.addEventListener('resize', e => {
        if(window.innerWidth >= 1024 && !this.isDesktop){
            this.isDesktop = true
        }else if(window.innerWidth < 1024 && this.isDesktop){
            this.isDesktop = false
        }
        })
    },
    methods: {
      importImage(src) {
        return require(`../images/discount-callout/${src}`)
      },
    },
  }
  </script>
  