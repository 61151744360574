<template>
  <div
    id="discount-ticker"
    class="discount-ticker"
  >
    <h2
      v-if="title"
      class="title"
      v-html="title"
    />

    <div
      class="marquee-wrapper"
      :class="`offers-${offerLength}`"
    >
      <div
        v-for="n in 2"
        :key="`ticker-wrapper-${n}`"
        class="ticker-wrapper"
      >
        <div
          v-for="(deal, i) in dealElements"
          :key="i"
          class="ticker-deal"
        >
          <img
            v-if="deal.icon"
            class="ticker-icon"
            :src="importImage(deal.icon)"
            alt="Discount Icon"
          >
          <h3
            v-if="deal.headline || deal.kicker"
            :class="{ 'ticker-headline': !!deal.headline, 'ticker-kicker': !!deal.kicker }"
            v-html="deal.headline || deal.kicker"
          />
          <h4
            v-if="deal.offer"
            class="ticker-title"
            v-html="deal.offer"
          />
          <h6
            v-if="deal.expire_text"
            class="expire-text"
            v-html="deal.expire_text"
          />
          <p
            v-if="deal.disclaimer"
            class="ticker-disclaimer"
            v-html="deal.disclaimer"
          />
        </div>
      </div>
    </div>
    <p
      v-if="disclaimerNotice"
      class="disclaimer-notice"
      v-html="disclaimerNotice"
    />
    <small class='discount-disclaimer-small'>*Interest is billed during promo period but will be waived if the amount financed is paid in full before promo period expires.</small>
  </div>
</template>

<script>
/* eslint-disable no-param-reassign */
import data from '../data/discountTicker.json'

export default {
  props: {
    title: {
      type: String,
      default: null,
    },
    disclaimerNotice: {
      type: String,
      default: null,
    },
  },
  data() {
    const offerKey = this.$quizData.discount
    const offers = offerKey ? data[offerKey] : data.default
    const dealElements = offers.concat(offers)

    return {
      offerLength: offers.length,
      dealElements,
    }
  },
  methods: {
    importImage(src) {
      return require(`../images/discount-callout/${src}`)
    },
  },
}
</script>
