<template>
  <article class="longform-section listicle-section">
    <div class="container">
      <div
        v-if="content.title || content.text"
        class="longform-hero"
      >
        <h2
          v-if="content.title"
          v-html="content.title"
        />
        <p
          v-if="content.text"
          v-html="content.text"
        />
      </div>
      <div class="longform-body">
        <div
          v-for="(pair, index) in content.pairs"
          :key="index"
          class="image-text-pair"
        >
          <figure
            v-if="pair.image"
            class="media-block"
          >
            <picture>
              <img :src="pair.image">
            </picture>
            <figcaption>
              <span
                class="media-caption"
                v-html="pair.title"
              />
            </figcaption>
          </figure>
          <div class="text-wrapper">
            <div class="title-block">
              <h3 v-html="pair.title" />
              <p
                class="lead"
                v-html="pair.text"
              />
            </div>
          </div>
        </div>
        <div
          v-if="content.ctaText && content.ctaLink"
          class="cta-block"
        >
          <div class="cta-wrapper">
            <a
              class="btn"
              v-scroll-to="{
                el: content.ctaLink,
                offset: -30,
                duration: 500,
              }"
              href="#"
              @click.prevent="handleAnchorClick"
              v-html="content.ctaText"
            />
          </div>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
export default {
  props: {
    content: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    handleAnchorClick() {
      return window.rba.tracking.trackAnchorClick('mid-cta', 'cta-click')
    },
  }
}
</script>
