<template>
  <div>
    <StickyQuizTop
      v-if="showStickyTop"
      :path="path"
      :content="$quizData.show_countdown_linkbar"
    />
    <StickyQuizTopv2
      v-if="showStickyTopv2"
      :content="$quizData.show_timed_linkbar ? {start: $quizData.linkbar_start_date, end: $quizData.linkbar_end_date, ...$quizData.sticky_header_v2} : $quizData.sticky_header_v2"
    />
    <div
      v-if="(stickyDiscountBanner && typeof $quizData.showStickyTopv3 !== undefined)"
      class="sticky-top-container"
    >
      <DiscountBanner
            v-if="stickyDiscountBanner"
            :stapleTitle="$quizData.discountSection.stapleTitle"
          />
      <StickyQuizTopv2
        :content="$quizData.show_timed_linkbar ? {start: $quizData.linkbar_start_date, end: $quizData.linkbar_end_date, ...$quizData.sticky_header_v2} : $quizData.sticky_header_v2"
      />
    </div>
    <ThesisForm
      v-if="isMultiPageEmbed && !isAfVariant"
      :class="classObject"
    >
      <template #headerSlot>
        <StaticHeroIntro v-if="isStaticHeroIntro" />
        <SplitHeroIntro v-if="isSplitHeroIntro" />
        <SplitHeroIntrov2 v-if="isSplitHeroIntrov2" />
        <SplitHeroIntrov3
          v-if="isSplitHeroIntrov3"
          :path="path"
        />
        <MiniQuizIntro
          v-if="isMiniQuiz && $quizData.mini_quiz_intro"
          :content="$quizData.mini_quiz_intro"
        />
        <DiscountSection
          v-if="!$quizData.hideDiscountSection"
          :showDiscountSection="$quizData.discountSection !== undefined"
        />

        <!-- Multi-page quiz does not have id to jump to -->
        <div
          id="quiz-start"
          class="quiz-start"
        />
        <div
          id="quiz-start-bottom"
          class="quiz-start"
          style="padding-top:5.9375rem; margin-top:-5.9375rem; pointer-events: none;"
        />
        <AboveQuizTitle
          v-if="$quizData.aboveQuizTitle"
        />
      </template>
      <template #loaderView>
        <ResultsContainer
          v-if="isFormResults && !hasFormQuizQuestions"
          :path="path"
        />
        <!-- Add component for in-quiz form results-->
        <InQuizFormResults
          v-if="isFormResults && hasFormQuizQuestions"
          :path="path"
        />
        <div v-else />
      </template>
      <template #footerSlot>
        <QuizBottom
          v-if="!isHideQuizBottom"
          :path="path"
          @scrolledPastQuestions="resultsVisible"
        />
      </template>
    </ThesisForm>
    <div v-else-if="isMultiPageEmbed && isAfVariant" 
      class="hero-quiz-variant" 
      :class="classObject">
      <!-- <DiscountSection
          :showDiscountSection="$quizData.discountSection !== undefined"
        /> -->
      <SplitHeroIntrov4
          v-if="isSplitHeroIntrov4"
          :path="path"
      />
      <QuizBottom
          v-if="!isHideQuizBottom"
          :path="path"
          @scrolledPastQuestions="resultsVisible"
        />
    </div>
    <ThesisForm
      v-else
      :class="classObject"
    >
      <template #onePageIntro>
        <template v-if="isListicle">
          <ListicleIntro :path="path" />
        </template>
        <template v-else-if="isStaticHeroIntro">
          <StaticHeroIntro />
        </template>
        <template v-else-if="isSplitHeroIntro">
          <SplitHeroIntro />
        </template>
        <template v-else>
          <DynamicHeroIntro :path="path" />
        </template>
      </template>
      <template #onePageTop>
        <ScrollDown v-if="!scrolledPastQuestions && !hide_scroll" />
      </template>
      <template #onePageBottom>
        <QuizBottom
          :path="path"
          @scrolledPastQuestions="resultsVisible"
        />
      </template>
    </ThesisForm>
  </div>
</template>

<script>
import DynamicHeroIntro from './sections/DynamicHeroIntro.vue'
import ListicleIntro from './sections/ListicleIntro.vue'
import StaticHeroIntro from './sections/StaticHeroIntro.vue'
import SplitHeroIntro from './sections/SplitHeroIntro.vue'
import MiniQuizIntro from './sections/MiniQuizIntro.vue'
import QuizBottom from './sections/QuizBottom.vue'
import ScrollDown from './components/ScrollDown.vue'
import StickyQuizTop from './sections/StickyQuizTop.vue'
import StickyQuizTopv2 from './sections/StickyQuizTop_v2.vue'
import SplitHeroIntrov2 from './sections/SplitHeroIntro_v2.vue'
import SplitHeroIntrov3 from './sections/SplitHeroIntro_v3.vue'
import SplitHeroIntrov4 from './sections/SplitHeroIntro_v4.vue'
import AboveQuizTitle from './components/AboveQuizTitle.vue'

import InQuizFormResults from './components/InQuizFormResults.vue'

// Testing new section
import DiscountSection from './sections/DiscountSection.vue'
import DiscountBanner from './components/DiscountBanner.vue'
const ResultsContainer = () => import('./components/ResultsContainer.vue')

export default {
  components: {
    StickyQuizTop,
    StickyQuizTopv2,
    ScrollDown,
    QuizBottom,
    ListicleIntro,
    StaticHeroIntro,
    SplitHeroIntro,
    SplitHeroIntrov2,
    SplitHeroIntrov3,
    SplitHeroIntrov4,
    MiniQuizIntro,
    DynamicHeroIntro,
    ResultsContainer,
    AboveQuizTitle,
    DiscountSection,
    InQuizFormResults,
    DiscountBanner,
  },
  data() {
    const {
      compact,
      listicle,
      quiz_v2,
      rebrand,
      reskin,
      hide_sticky_top,
      staticHeroIntro,
      splitHeroIntro,
      splitHeroIntrov2,
      splitHeroIntrov3,
      splitHeroIntrov4,
      is_multi_page_embed,
      is_af_variant,
      is_form_results,
      hide_quiz_bottom,
      skip_to_results,
      show_sticky_top_v2,
      is_mini_quiz,
      hasFormQuizQuestions,
      hide_scroll_arrow,
      sticky_discount_banner
    } = this.$quizData

    return {
      isCompact: compact,
      isListicle: !!listicle,
      isQuizV2: quiz_v2,
      isRebrandPage: rebrand,
      isReskinnedPage: reskin,
      isStaticHeroIntro: !!staticHeroIntro,
      isSplitHeroIntro: !!splitHeroIntro,
      isSplitHeroIntrov2: !!splitHeroIntrov2,
      isSplitHeroIntrov3: !!splitHeroIntrov3,
      isSplitHeroIntrov4: !!splitHeroIntrov4,
      showStickyTop: !hide_sticky_top,
      isMultiPageEmbed: is_multi_page_embed,
      isAfVariant: is_af_variant,
      isFormResults: is_multi_page_embed && is_form_results,
      isHideQuizBottom: !!hide_quiz_bottom,
      isSkipToResults: !!skip_to_results,
      scrolledPastQuestions: false,
      showStickyTopv2: show_sticky_top_v2,
      isMiniQuiz: !!is_mini_quiz,
      hasFormQuizQuestions,
      hide_scroll: hide_scroll_arrow,
      stickyDiscountBanner: !!sticky_discount_banner
    }
  },
  computed: {
    path() {
      let path = window.location.pathname
      if (path.includes('_stg')) {
        path = `/${path.split('/').slice(3).join('/')}`
      }
      return path.endsWith('/') ? path.slice(0, -1) : path
    },
    classObject() {
      return {
        'listicle-template': this.isListicle,
        'reskinned-page': this.isReskinnedPage,
        'rebrand-page': this.isRebrandPage,
        'quiz-compact': this.isCompact,
        'quiz-v2': this.isQuizV2,
        'quiz-v1': !this.isQuizV2 && !this.isCompact,
      }
    },
    quizAnswers() {
      return this.$store.getters.RESULTS
    },
    isFirstQuestion() {
      return this.$store.getters.RESULTS[0].content.length === 0
    },

  },
  watch: {
    quizAnswers(newVal, oldVal) {
      if (newVal !== oldVal && !this.isFirstQuestion) {
        this.$scrollTo('.progress-bar', 1000, {
          offset: -150,
        })
      }
    },

  },
  mounted() {
    if (!this.isMultiPageEmbed) this.trackFinalQuestion()
    if (this.isSkipToResults) this.$store.dispatch('QUIZ_COMPLETED')
  },
  created() {
    this.$store.dispatch('INITIALIZE_STORE', this.$quizData)
    this.$store.dispatch('/results/INITIALIZE_RESULTS', this.$quizData.sequences)
  },
  methods: {
    resultsVisible(isVisible) {
      this.scrolledPastQuestions = isVisible
    },
    trackFinalQuestion() {
      const questions = [...document.querySelectorAll('.question')]
      const lastQuestion = questions.slice(-1)[0]
      let eventFired = false
      lastQuestion.addEventListener('click', () => {
        if (!eventFired) {
          window.rba.tracking.finalQuestion()
          eventFired = true
        }
      })
    },
  },
}
</script>
