<!-- eslint-disable vue/no-v-html -->
<template>
  <div v-if="isReskinnedPage" :class="classes">
    <div class="hero-content-wrapper">
      <TitleBlock
        :kicker="customHeroContent ? customHeroContent.kicker : content.kicker"
        :title="content.title"
        :subtitle="content.subtitle"
        :text="content.text"
        :isAltKicker="isReskinnedPage"
      />
      <div class="cta-block">
        <div class="cta-content">
          <p v-if="content.text_above_cta" v-html="content.text_above_cta" />
          <div class="btn-container">
            <button class="tf-btn" @click="startQuiz">
              {{ content.cta_text }}
            </button>
          </div>
        </div>
        <small v-html="content.text_below_cta" />
      </div>
    </div>
    <p
      v-if="isReskinnedPage"
      class="hero-disclaimer"
      v-html="content.text_below_cta"
    />
  </div>
  <div v-else :class="classes">
    <TitleBlock
      :kicker="content.kicker"
      :title="content.title"
      :subtitle="content.subtitle"
      :text="content.text"
    />
    <div v-if="showVideo" class="video-block">
      <video :poster="poster" @click="videoClick()">
        <source
          src="https://d1pm06yvbkxf21.cloudfront.net/rba/video-march.mp4"
        />
      </video>
    </div>
    <div class="cta-block">
      <div class="cta-content">
        <p v-if="content.text_above_cta" v-html="content.text_above_cta" />
        <div class="btn-container">
          <button class="tf-btn" @click="startQuiz">
            {{ content.cta_text }}
          </button>
        </div>
      </div>
      <small v-html="content.text_below_cta" />
    </div>
  </div>
</template>
<script>
import data from '../data/hero.json';
import TitleBlock from './TitleBlock.vue';
import poster from '../images/video-poster.jpg';

export default {
  components: {
    TitleBlock,
  },
  props: {
    isCorpPage: {
      type: Boolean,
      default: false,
    },
    isEslerPage: {
      type: Boolean,
      default: false,
    },
    isMoorePage: {
      type: Boolean,
      default: false,
    },
    path: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      data,
      poster,
      videoPlayed: false,
      pausedTime: 0,
      phoneNumber: gon.phone_number,
      isReskinnedPage: this.$quizData.reskin,
    };
  },
  computed: {
    content() {
      const { path } = this;

      // joint page
      if (path.includes('podcasts')) {
        return data.podcast.content;
      }

      // esler pages
      if (this.isEslerPage) {
        // if (this.isReskinnedPage) {
        //   // search product pages
        //   return data.eslerReskin.content
        // }

        return data.esler.content;
      }

      // moore pages
      if (this.isMoorePage) {
        // // toronto copy
        if (path.includes('toronto')) {
          return data.mooreToronto.content;
        }
        // // midwest/indiana copy
        // if (path.includes('midwest') || path.includes('indiana/email')) {
        //   return data.mooreMidwest.content
        // }
        // // SF copy
        // if (path.includes('sf')) {
        //   return data.mooreSF.content
        // }
        // // gen copy
        // if (path.includes('gen')) {
        //   return data.mooreGen.content
        // }
        // competitive copy
        if (path.includes('comp')) {
          return data.mooreComp.content;
        }
        // default copy - nj/li/at
        return data.moore.content;
      }

      // corp pages
      if (this.isCorpPage) {
        // national copy
        if (path.includes('national')) {
          return data.corpNational.content;
        }

        return data.corp.content;
      }

      return '';
    },
    customHeroContent() {
      return this.$quizData.customHeroContent;
    },
    isQuizV1Styling() {
      return this.path.includes('podcasts');
    },
    showVideo() {
      return this.path.includes('/esler/video');
    },
    classes() {
      if (this.customHeroContent) {
        return this.customHeroContent.classes;
      }
      if (this.isReskinnedPage) {
        return 'variant-hero';
      }
      if (this.path === '/esler/audio') {
        return 'simple-hero jdpower';
      }
      if (this.isQuizV1Styling) {
        return 'simple-hero quiz-v1-hero';
      }
      if (this.showVideo) {
        return 'video-hero';
      }
      return 'simple-hero';
    },
  },
  mounted() {
    this.$store.dispatch('HERO_DATA', this.content);
  },
  methods: {
    startQuiz() {
      const offsetHeight = `-${
        document.querySelector('.sticky-quiz-top').offsetHeight +
        document.querySelector('.quiz-header').offsetHeight
      }`;
      this.$scrollTo('#onepage', 250, {
        offset: parseInt(offsetHeight),
      });
      window.rba.tracking.startQuiz();
    },
    videoClick() {
      const video = document.getElementsByTagName('video')[0];
      if (video.paused) {
        video.currentTime = this.pausedTime;
        video.play();
      } else {
        this.pausedTime = video.currentTime;
        video.load();
      }
      if (!this.videoPlayed) {
        window.rba.tracking.trackCustomClickEvent('play-video');
        this.videoPlayed = true;
      }
    },
  },
};
</script>
