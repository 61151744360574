<template>
  <section
    v-if="showDiscountSection"
    id="discount-section"
    :class="['discount-section', discountData.classes]"
  >
    <DiscountCallout
      v-if="discountData.showCallout"
    />
    <DiscountTicker
      v-if="discountData.showTicker"
      :title="discountData.discountTitle"
      :disclaimerNotice="discountData.disclaimerNotice"
    />
    <DiscountCarousel
      v-if="discountData.showCarousel || discountData.showCarouselv2 || discountData.showCarouselv3"
      :isV2="discountData.showCarouselv2"
      :isV3="discountData.showCarouselv3"
    />
    <DiscountCards
      v-if="discountData.showCards || discountData.showCardsv2"
      :isV2="discountData.showCardsv2"
      :stapleTitle="discountData.stapleTitle"
    />
    <DiscountStacked
      v-if="discountData.showStacked"
      :offers="offer"
    />
    <DiscountBanner
      v-if="discountData.showBanner"
      :stapleTitle="discountData.stapleTitle"
    />
  </section>
</template>

<script>
import discountConfig from '../data/discountConfig.json'

import DiscountCallout from '../components/DiscountCallout.vue'
import DiscountTicker from '../components/DiscountTicker.vue'
import DiscountCarousel from '../components/DiscountCarousel.vue'
import DiscountCards from '../components/DiscountCards.vue'
import DiscountStacked from '../components/DiscountStacked.vue'
import DiscountBanner from '../components/DiscountBanner.vue'

export default {
  components: {
    DiscountCallout,
    DiscountTicker,
    DiscountCarousel,
    DiscountCards,
    DiscountStacked,
    DiscountBanner
  },
  props: {
    showDiscountSection: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const offerKey = this.$quizData.discount
    const offer = discountConfig[offerKey] ? discountConfig[offerKey] : null
    return {
      offer,
    }
  },
  computed: {
    discountData() {
      return this.$quizData.discountSection
    },
  },
}
</script>
<style>
  .discount-disclaimer-small {
    transform: translateY(1.5625rem);
    padding-left: 1rem;
    padding-right: 1rem;
  }
</style>
