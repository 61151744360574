var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{class:['sticky-quiz-top-v2', _vm.content.classes]},[(_vm.content.linkbar_text && _vm.content.linkbar_link && !_vm.content.start && !_vm.content.end && !_vm.$quizData.show_timed_linkbar)?_c('a',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
      el: _vm.content.linkbar_link,
      offset: -150,
      duration: 500,
    }),expression:"{\n      el: content.linkbar_link,\n      offset: -150,\n      duration: 500,\n    }"}],staticClass:"sticky-linkbar",attrs:{"data-trackable":"banner","data-interaction":"cta-click","href":_vm.content.linkbar_link},domProps:{"innerHTML":_vm._s(_vm.content.linkbar_text)}}):_vm._e(),_vm._v(" "),(_vm.content.start && _vm.content.end && _vm.$quizData.show_timed_linkbar)?_c('LinkBarTimed',{attrs:{"endDate":_vm.getEndDateMemo(),"content":{}}}):_vm._e(),_vm._v(" "),_c('header',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
      el: _vm.content.header_link || '',
      offset: -100,
      duration: 500,
    }),expression:"{\n      el: content.header_link || '',\n      offset: -100,\n      duration: 500,\n    }"}],class:_vm.content.header_link ? 'link-pointer sticky-header' : 'sticky-header'},[(_vm.content.logo_path)?_c('img',{attrs:{"src":_vm.content.logo_path,"alt":"RBA Logo"},on:{"click":_vm.handleLogoClick}}):_vm._e(),_vm._v(" "),_c('a',{staticClass:"sticky-btn",attrs:{"data-trackable":"general","data-interaction":"phone-call","href":'tel:' + _vm.phoneUrl},on:{"click":_vm.handlePhoneClick}},[_vm._v("\n      Call Now"),_c('span',{staticClass:"gc-number"},[_vm._v(" "+_vm._s(_vm.phoneUrl))])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }