<template>
  <figure class="media-block">
    <picture>
      <source
        v-if="content.lg_image"
        :srcset="content.lg_image"
        media="(min-width: 1280px)"
      >
      <source
        v-if="content.md_image"
        :srcset="content.md_image"
        media="(min-width: 768px)"
      >
      <img :src="content.image">
    </picture>
    <figcaption
      v-if="content.media_caption"
      v-html="content.media_caption"
    />
  </figure>
</template>

<script>
export default {
  props: {
    content: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
