<template>
  <div
    id="discount-cards"
    :class="['discount-cards', { 'discount-cards-v2': isV2 }]"
    :data-cards="JSON.stringify($quizData.dynamic_discount) || 'static'"
  >
    <div class="container">
      <div class="title-wrapper">
        <h2
          v-if="stapleTitle"
          class="title"
          v-html="stapleTitle"
        />
        <h2
          v-else
          class="title"
        >
          No interest,<br>
          No money down,<br>
          No payments for<br>
          <span class="year-text">1 year*</span>
        </h2>

        <div class="arrows-container">
          <img
            id="left-arrow"
            :src="arrow"
            alt="left arrow"
            @click="transitionLeft"
          >
          <img
            id="right-arrow"
            :src="arrow"
            alt="right arrow"
            @click="transitionRight"
          >
        </div>
      </div>

      <div
        class="cards-container"
        @touchstart="touchStart"
        @touchend="touchEnd"
      >
        <div id="back-card-animated" />
        <div class="back-card" />
        <div class="mid-card" />
        <div
          id="active-card"
          class="card"
          @click="transitionRight"
        >
          <h3
            v-if="getCurrentIndex.headline"
            class="card-headline"
            v-html="getCurrentIndex.headline"
          />
          <h4
            v-if="getCurrentIndex.offer"
            class="card-title"
            v-html="getCurrentIndex.offer"
          />
          <h6
            v-if="getCurrentIndex.expire_text"
            class="card-expire"
            v-html="getCurrentIndex.expire_text"
          />

          <p
            v-if="getCurrentIndex.disclaimer"
            class="card-disclaimer"
            v-html="getCurrentIndex.disclaimer"
          />
        </div>
      </div>

      <div class="card-count">
        <p class="card-count-text">
          {{ currentIndex + 1 }}/{{ offersLength }}
        </p>
      </div>
    </div>
    <small class='discount-disclaimer-small'>*Interest is billed during promo period but will be waived if the amount financed is paid in full before promo period expires.</small>
  </div>
</template>

<script>
import arrow from '../images/arrow-white-fill.svg'
import data from '../data/discountTicker.json'

export default {
  props: {
    isV2: {
      type: Boolean,
      default: false,
    },
    stapleTitle: {
      type: String,
      default: null,
    },
  },
  data() {
    const offerKey = this.$quizData.discount
    const dynamic_offer = this.$quizData.dynamic_discount
    //Checks for dynamic_offer, then offerKey, then defaults otherwise
    //Will probably be able to remove check for offerKey in the future but this way we can still hardcode offers if needed.
    //let offers = dynamic_offer.length() !== 0 ? dynamic_offer : offerKey  ? data[offerKey] : data.default
    let offers = data.default
    if(dynamic_offer){
      offers = dynamic_offer
    }
    else if(offerKey){
      offers = data[offerKey]
    }
    
    if(this.$quizData.dynamic_first_card){
      offers.unshift(
        this.$quizData.dynamic_first_card[0]
      )
    }
    else if (offerKey.includes('blackfriday')){
      offers.unshift(
        { expire_text: 'BLACK FRIDAY SALE! TAKE THE QUIZ TO CLAIM YOUR OFFER.', disclaimer: 'Swipe to see&nbsp;more!' },
      )
    }
    else if (offerKey.includes('moore') || offerKey.includes('toronto')) {
      offers.unshift(
        { expire_text: 'OFFER EXTENDED! TAKE THE QUIZ TO CLAIM YOUR&nbsp;OFFER.', disclaimer: 'SWIPE TO SEE&nbsp;MORE!' },
      )
    }
    else {
      offers.unshift(
        { expire_text: 'Limited Time Only! Take the Quiz to Claim Your&nbsp;Offer.', disclaimer: 'Swipe to see&nbsp;more!' },
      )
    }
    offers = offers.filter(off => {
      //Added toLowerCase() to ensure filter
      if (off.offer) {

        return !(off.offer.toLowerCase().includes("don't pay anything") || off.offer.toLowerCase().includes("no interest") || off.offer.toLowerCase().includes("0% interest"))
      }

      return true
    })

    return {
      offersLength: offers.length,
      offers,
      currentIndex: 0,
      startX: 0,
      endX: 0,
      isTransitionActive: false,
      arrow,
    }
  },
  computed: {
    getCurrentIndex() {
      return this.offers[this.currentIndex]
    },
  },
  methods: {
    touchStart(touchEv) {
      if (touchEv.changedTouches.length !== 1) {
        return
      }

      this.startX = touchEv.changedTouches[0].clientX
    },
    touchEnd(touchEv) {
      if (touchEv.changedTouches.length !== 1) {
        return
      }

      this.endX = touchEv.changedTouches[0].clientX

      if (this.endX < this.startX) {
        this.transitionRight()
      } else if (this.endX > this.startX) {
        this.transitionLeft()
      }
    },
    transitionRight() {
      if (!this.isTransitionActive) {
        const backCardAnimated = document.getElementById('back-card-animated')
        backCardAnimated.classList.add('animate-card-from-back')

        const mainCard = document.getElementById('active-card')
        mainCard.classList.add('hide-text')

        this.isTransitionActive = true

        setTimeout(() => {
          backCardAnimated.classList.remove('animate-card-from-back')

          mainCard.classList.remove('hide-text')
          this.currentIndex = (this.currentIndex + 1) % this.offersLength

          this.isTransitionActive = false
        }, 600)
      }
    },
    transitionLeft() {
      if (!this.isTransitionActive) {
        const backCardAnimated = document.getElementById('back-card-animated')
        backCardAnimated.classList.add('animate-card-from-back')

        const mainCard = document.getElementById('active-card')
        mainCard.classList.add('hide-text')

        this.isTransitionActive = true

        setTimeout(() => {
          backCardAnimated.classList.remove('animate-card-from-back')
          mainCard.classList.remove('hide-text')

          this.currentIndex = (this.currentIndex - 1) % this.offersLength
          if (this.currentIndex < 0) this.currentIndex = this.offersLength - 1

          this.isTransitionActive = false
        }, 600)
      }
    },
  }
}
</script>
