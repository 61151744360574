<template>
  <section
    class="page-section hero-section static-hero"
    :class="content.classes"
  >
    <div class="container">
      <div class="hero-content">
        <div class="title-block">
          <h2
            v-if="content.title"
            v-html="content.title"
          />
          <p
            v-if="content.text"
            v-html="content.text"
          />
        </div>
        <div
          v-if="content.ctaText && content.ctaLink"
          class="cta-block"
        >
          <div class="cta-wrapper">
            <a
              v-scroll-to="{
                el: content.ctaLink,
                offset: content.offset ? parseInt(content.offset) : -30,
                duration: 500,
              }"
              href="#"
              class="btn"
              @click.prevent="handleAnchorClick"
              v-html="content.ctaText"
            />
          </div>
        </div>
        <div
          v-if="content.press"
          class="press-container"
        >
          <div
            v-for="(pressIcon, idx) in content.press"
            :key="`press-${idx}`"
            class="press-icon"
          >
            <img
              :src="pressIcon.image"
              :alt="pressIcon.alt"
            >
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    content: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    handleAnchorClick() {
      return window.rba.tracking.trackAnchorClick(this.content.tracking || 'hero-cta', this.content.interaction || 'cta-click')
    },
  },
}
</script>
