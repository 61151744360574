import Vue from 'vue'
import ThesisForm from 'thesis-form'
import Vuelidate from 'vuelidate'
import VueTheMask from 'vue-the-mask'
import VueObserveVisibility from 'vue-observe-visibility'
import vSelect from 'vue-select'
import App from '../App.vue'
import store from '../store/index'
import 'vue-loading-overlay/dist/vue-loading.css'
import 'vue-select/dist/vue-select.css'

const VueScrollTo = require('vue-scrollto')

const DiscountTicker = () => import('../components/DiscountTicker')

Vue.use(ThesisForm)
Vue.use(Vuelidate)
Vue.use(VueTheMask)
Vue.use(VueScrollTo)
Vue.use(VueObserveVisibility)
Vue.use(require('vue-moment'))

Vue.component('v-select', vSelect)

Vue.config.productionTip = false

document.addEventListener('DOMContentLoaded', () => {
  // select element in vue mount point partial
  const element = document.getElementById('vue-mount')

  if (element) {
    // Get data from element's data-quiz attribute
    const quizData = JSON.parse(element.dataset.quiz)
    Vue.prototype.$quizData = quizData

    // Get TV LP data and assign it based on param
    let data = {}
    if (quizData.is_tv_lp) {
      const param = new URLSearchParams(window.location.search).get('rba_referrer')
      // eslint-disable-next-line global-require
      const json = require('../data/tvDataMap.json')
      data = json[param] || {
        breakdown: 'Corporate Direct',
        source: 'Organic Web – Appt Request',
      }
    }
    Vue.prototype.$tvData = data

    // eslint-disable-next-line no-unused-vars
    const vm = new Vue({
      el: element,
      // pass down vuex store to all components
      store,
      render: h => h(App),
    })

    // Used by taboola article to have quiz + discount ticker in different places
    if (quizData.add_discount_vue_instance) {
      const element2 = document.getElementById('vue-mount-2')

      if (element2) {
        // eslint-disable-next-line no-unused-vars
        const vm2 = new Vue({
          el: element2,
          render: h => h(DiscountTicker),
        })
      }
    }
  }
})
