<template>
  <div
    class="sticky-quiz-top"
    :class="{'has-sticky-header': showStickyHeader }"
  >
    <LinkBarTimed
      v-if="showCountdownLinkbar"
      :classes="content.classes"
      :endDate="endDate"
      :text="content.text"
      :content="$quizData.show_countdown_linkbar"
    />
    <LinkBar
      v-if="showDefaultLinkbar"
      :showPhoneHeader="showPhoneHeader"
      :showCountdownLinkbar="showCountdownLinkbar"
    />
    <StickyHeader
      v-if="showStickyHeader"
    />
  </div>
</template>

<style>
.sticky-quiz-top.has-sticky-header + .one-page > .quiz-header {
  display: none;
}
</style>

<script>
import LinkBar from '../components/LinkBar.vue'

const LinkBarTimed = () => import('../components/LinkBarTimed.vue')
const StickyHeader = () => import('../components/StickyHeader.vue')

export default {
  components: {
    LinkBar,
    LinkBarTimed,
    StickyHeader,
  },
  props: {
    path: {
      type: String,
      default: '',
    },
    content: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      endDate: this.getEndDate(),
      showPhoneHeader: this.$quizData.show_phone_header,
      showStickyHeader: this.$quizData.show_sticky_header,
    }
  },
  computed: {
    showDefaultLinkbar() {
      return !this.$quizData.hide_default_linkbar
    },
    showCountdownLinkbar() {
      const today = this.$moment().unix()
      const countdownEnd = this.endDate.unix()

      return (today < countdownEnd) && this.$quizData.show_countdown_linkbar !== undefined
    },
  },
  methods: {
    getEndDate() {
      if (this.path.includes('moore')) {
        if (this.path.includes('toronto')) return this.$moment([2021, 6, 5, 23, 59, 59])
        if (this.path.includes('gen/5') || this.path.includes('midwest/5')) return this.$moment([2022, 3, 30, 23, 59, 59])
        if (this.path.includes('gen/6') || this.path.includes('midwest/6')) return this.$moment().endOf('day')

        return this.$moment().endOf('day')
      }
      if (this.path.includes('esler/gen')) {
        if (this.path.includes('/5') || this.path.includes('/tx-ok/5')) return this.$moment([2022, 3, 17, 23, 59, 59])
        if (this.path.includes('/6') || this.path.includes('/tx-ok/6')) return this.$moment().endOf('day')
      }

      if (this.$quizData.show_countdown_linkbar) return this.$moment() // use new endTime props

      return this.$moment([2021, 4, 31, 23, 59, 59])
    },
  },
}
</script>
