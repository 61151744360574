<template>
  <form
    v-if="isListicle || isRebrand || isReskinnedPage"
    id="quiz-form"
    class="hero-form quiz-form-rebrand"
  >
    <LoadingGif :show="isSubmitting" />
    <div v-if="heroFormTitle">
      <h2
        v-if="heroFormTitle"
        v-html="heroFormTitle"
      />
    </div>
    <div
      v-if="formTopCopy.title || formTopCopy.text"
      class="form-text-container"
    >
      <h3
        v-if="formTopCopy.title"
        v-html="formTopCopy.title"
      />
      <p
        v-if="formTopCopy.text"
        v-html="formTopCopy.text"
      />
    </div>
    <div class="fields-container">
      <fieldset>
        <label for="first-name">First Name*</label>
        <input
          id="first-name"
          v-model="form.firstName"
          type="text"
          name="first-name"
          :placeholder="
            formTopCopy.first_placeholder || 'Enter your first name'
          "
        >
        <p
          v-if="errors.firstName"
          class="error-msg"
        >
          Please provide your first name
        </p>
      </fieldset>
      <fieldset>
        <label for="last-name">Last Name*</label>
        <input
          id="last-name"
          v-model="form.lastName"
          type="text"
          name="last-name"
          :placeholder="formTopCopy.last_placeholder || 'Enter your last name'"
        >
        <p
          v-if="errors.lastName"
          class="error-msg"
        >
          Please provide your last name
        </p>
      </fieldset>
      <fieldset>
        <div
          v-show="email_tooltip"
          v-if="formTopCopy.email_tooltip"
          id="email_tooltip"
          :ref="'email_tooltip'"
          class="tooltip_text"
        >
          <span
            v-if="formTopCopy.email_tooltip_header"
            v-html="formTopCopy.email_tooltip_header"
          />
          <p v-html="formTopCopy.email_tooltip" />
        </div>
        <label
          for="email"
        >Email Address*<span
          v-if="formTopCopy.email_tooltip"
          class="tooltip email_tooltip"
          tabindex="-1"
          @click.prevent="toggleTooltip('email_tooltip')"
          @blur="hideTooltip('email_tooltip')"
        /></label>
        <input
          id="email"
          v-model="form.email"
          type="email"
          name="email"
          :placeholder="formTopCopy.email_placeholder || 'Enter your email'"
        >
        <p
          v-if="errors.email"
          class="error-msg"
        >
          Please enter a valid email address
        </p>
      </fieldset>
      <fieldset>
        <label
          for="phone"
        >Phone Number*<span
          v-if="formTopCopy.phone_tooltip"
          class="tooltip phone_tooltip"
          tabindex="-1"
          @click.prevent="toggleTooltip('phone_tooltip')"
          @blur="hideTooltip('phone_tooltip')"
        /></label>
        <div
          v-show="phone_tooltip"
          v-if="formTopCopy.phone_tooltip"
          id="phone_tooltip"
          :ref="'phone_tooltip'"
          class="tooltip_text"
        >
          <span
            v-if="formTopCopy.phone_tooltip_header"
            v-html="formTopCopy.phone_tooltip_header"
          />
          <p v-html="formTopCopy.phone_tooltip" />
        </div>
        <input
          id="phone"
          v-model="form.phoneNumber"
          v-mask="'(###) ###-####'"
          type="tel"
          name="phone-number"
          :placeholder="formTopCopy.phone_placeholder || '(123) 456-7890'"
        >
        <p
          v-if="errors.phoneNumber"
          class="error-msg"
        >
          Please enter a valid phone number
        </p>
      </fieldset>
      <fieldset v-if="isCanada">
        <label
          for="postal"
        >Postal Code*<span
          v-if="formTopCopy.zip_tooltip"
          class="tooltip zip_tooltip"
          tabindex="-1"
          @click.prevent="toggleTooltip('zip_tooltip')"
          @blur="hideTooltip('zip_tooltip')"
        /></label>
        <div
          v-show="zip_tooltip"
          v-if="formTopCopy.zip_tooltip"
          id="zip_tooltip"
          :ref="'zip_tooltip'"
          class="tooltip_text"
        >
          <span
            v-if="formTopCopy.zip_tooltip_header"
            v-html="formTopCopy.zip_tooltip_header"
          />
          <p v-html="formTopCopy.zip_tooltip" />
        </div>
        <input
          id="postal"
          v-model="form.postalCode"
          v-mask="'XXX XXX'"
          type="text"
          name="zipcode"
          placeholder="Enter your 6-digit postal code"
        >
        <p
          v-if="errors.postalCode"
          class="error-msg"
        >
          Please enter a 6 character postal code
        </p>
      </fieldset>
      <fieldset v-else>
        <label
          for="zip"
        >Zip Code*<span
          v-if="formTopCopy.zip_tooltip"
          class="tooltip zip_tooltip"
          tabindex="-1"
          @click.prevent="toggleTooltip('zip_tooltip')"
          @blur="hideTooltip('zip_tooltip')"
        /></label>
        <div
          v-show="zip_tooltip"
          v-if="formTopCopy.zip_tooltip"
          id="zip_tooltip"
          :ref="'zip_tooltip'"
          class="tooltip_text"
        >
          <span
            v-if="formTopCopy.zip_tooltip_header"
            v-html="formTopCopy.zip_tooltip_header"
          />
          <p v-html="formTopCopy.zip_tooltip" />
        </div>
        <input
          id="zip"
          v-model="form.zipcode"
          type="tel"
          inputmode="decimal"
          name="zipcode"
          :placeholder="formTopCopy.zip_placeholder || '5-digit zip code'"
          maxlength="5"
        >
        <p
          v-if="errors.zipcode"
          class="error-msg"
        >
          Please enter a 5 digit zipcode
        </p>
      </fieldset>
    </div>

    <small
      v-if="isMoore"
      class="form-fine-print-agreement"
    >
      By clicking SUBMIT, you agree to the terms* below
    </small>

    <p
      v-if="formError"
      class="form-error"
    >
      {{ errorMessage }}
    </p>
    <div id="grecaptcha" />
    <div class="form-careers-text" v-if="isSih">
      <b>Looking for a job?</b> Check out our careers page <b><a class="career-link" href="https://www.renewalbyandersen.com/careers">here</a></b>.
      <br>
      <b>En busca de trabajo?</b> Visita nuestra pagina de carreras <b><a class="career-link" href="https://www.renewalbyandersen.com/careers">aquí</a></b>.
    </div>
    <button
      v-if="formTopCopy.v3"
      type="submit"
      class="btn btn-submit"
      :class="{ disabled: isDisabled }"
      :disabled="isDisabled || null"
      @click="submitForm"
      v-html="
        formTopCopy.cta_text ||
          submitText ||
          (isRebrand ? 'GET MY FREE CONSULTATION' : 'GET A FREE CONSULTATION')
      "
    />
    <input
      v-else
      type="submit"
      class="btn btn-submit"
      :class="{ disabled: isDisabled }"
      :value="
        formTopCopy.cta_text ||
          submitText ||
          (isRebrand ? 'GET MY FREE CONSULTATION' : 'GET A FREE CONSULTATION')
      "
      :disabled="isDisabled || null"
      @click="submitForm"
    >
    <p
      v-if="formTopCopy.v2"
      class="tooltip_v2_disclaimer"
    >
      Your Information is protected
    </p>
    <small
      v-if="isEsler"
      class="form-fine-print"
    >
      <span v-if="!isSih">
        <b>Looking for a job?</b> Check out our careers page <a class="career-link" href="https://www.renewalbyandersen.com/careers">here</a>.
        <br>
        <b>En busca de trabajo?</b> Visita nuestra pagina de carreras <a class="career-link" href="https://www.renewalbyandersen.com/careers">aquí</a>.
        <br><br>
      </span>
      
      By clicking "Submit", you are agreeing for Renewal by Andersen to contact you and keep you informed about our products and sales in the future.  
      We may use an auto-phone system to contact you by phone or text.  
      You do not need to make a purchase or submit this form but instead can call {{ phoneNumber }} to arrange for a free estimate. 
      You may revoke this consent at any time by contacting us or, for texts by replying STOP to any text message. 
      Messaging data rates may apply in regard to texting, message frequency varies. 
      You agree to the terms of our website’s dispute resolution policy and our <a class="career-link" href="https://www.renewalbyandersen.com/about/legal-privacy">Privacy Policy</a> and please be aware that all calls with us are recorded for quality and safety purposes.
    </small>
    <small
      v-else-if="isMoore"
      class="form-fine-print"
    >
      *I want to learn more about Renewal by Andersen and schedule an in-home
      price quote. By submitting this form, which I agree is my signature, I
      consent to receive recurring informational and advertising calls, texts,
      and emails from Renewal by Andersen and its affiliates and retailers,
      including by automated technology or prerecorded voice. I understand
      consent is not required for purchase and that I can opt out at any time,
      including (for texts) by replying “STOP”. I understand any Renewal by
      Andersen calls may be recorded. Renewal by Andersen collects certain
      categories of personal information and uses this information in various
      ways, including order fulfillment and providing product information and
      services, as further described in our Privacy Policy. I agree to Renewal
      by Andersen’s <a :href="privacyUrl">Privacy Policy</a> and
      <a :href="termsUrl">Terms and Conditions</a>. Message and data rates
      apply. California residents, click
      <a href="https://www.rbadataprivacy.com/California">here</a> for more
      information about the information we collect.
    </small>
    <small
      v-else
      class="form-fine-print"
    >
      I’d like to learn more about Renewal by Andersen windows. Please contact
      me at the phone number I listed above to schedule a convenient day and
      time for an in-home price quote. I am submitting this form so you can
      contact me and keep me informed about your products, services and when
      they go on sale. I understand that I do not need to submit this form to
      schedule an in-home price quote. Instead I may call your service center at
      the number on this page. I understand that all calls are recorded. Renewal
      by Andersen collects certain categories of personal information and uses
      this information in various ways, including order fulfillment and
      providing product information and service to you. For more information
      visit our <a :href="privacyUrl">privacy&nbsp;policy</a>.
    </small>
  </form>
  <form
    v-else
    class="quiz-form"
  >
    <div class="fields-container">
      <fieldset>
        <label for="first-name">First Name</label>
        <input
          v-model="form.firstName"
          type="text"
          name="first-name"
          placeholder="Enter your first name"
        >
        <p
          v-if="errors.firstName"
          class="error-msg"
        >
          Please provide your first name
        </p>
      </fieldset>
      <fieldset>
        <label for="last-name">Last Name</label>
        <input
          v-model="form.lastName"
          type="text"
          name="last-name"
          placeholder="Enter your last name"
        >
        <p
          v-if="errors.lastName"
          class="error-msg"
        >
          Please provide your last name
        </p>
      </fieldset>
      <fieldset v-if="isCanada">
        <label for="postal">Postal Code</label>
        <input
          v-model="form.postalCode"
          v-mask="'XXX XXX'"
          type="text"
          name="zipcode"
          placeholder="Enter your postal code"
        >
        <p
          v-if="errors.postalCode"
          class="error-msg"
        >
          Please enter a 6 character postal code
        </p>
      </fieldset>
      <fieldset v-else>
        <label for="zip">Zip Code</label>
        <input
          v-model="form.zipcode"
          type="text"
          inputmode="decimal"
          name="zipcode"
          placeholder="Enter your 5-digit zip code"
          maxlength="5"
        >
        <p
          v-if="errors.zipcode"
          class="error-msg"
        >
          Please enter a 5 digit zipcode
        </p>
      </fieldset>
      <fieldset>
        <label for="phone">Phone Number</label>
        <input
          v-model="form.phoneNumber"
          v-mask="'(###) ###-####'"
          type="tel"
          name="phone-number"
          placeholder="(123) 456-7890"
        >
        <p
          v-if="errors.phoneNumber"
          class="error-msg"
        >
          Please enter a valid phone number
        </p>
      </fieldset>
      <fieldset v-if="!isCanada">
        <label for="email">Email Address (optional)</label>
        <input
          v-model="form.email"
          type="email"
          name="email"
          placeholder="Enter your email"
        >
        <p
          v-if="errors.email"
          class="error-msg"
        >
          Please enter a valid email address
        </p>
      </fieldset>
    </div>
    <p v-if="formError">
      {{ errorMessage }}
    </p>
    <input
      type="submit"
      class="btn btn-submit"
      :value="submitButtonText"
      @click="submitForm"
    >

    <small
      v-if="isEsler"
      class="form-fine-print"
    >
      I'm interested in learning more about Renewal by Andersen products and
      services – I understand you may use an auto-phone system to contact me and
      you can use the telephone number I have provided above. I understand that
      no purchase is ever necessary and I do not need to submit this form in
      order to obtain a free price quote but instead I can call
      {{ phoneNumber }}.
    </small>
    <small
      v-else-if="isMoore"
      class="form-fine-print"
    >
      *I want to learn more about Renewal by Andersen and schedule an in-home
      price quote. By submitting this form, which I agree is my signature, I
      consent to receive recurring informational and advertising calls, texts,
      and emails from Renewal by Andersen and its affiliates and retailers,
      including by automated technology or prerecorded voice. I understand
      consent is not required for purchase and that I can opt out at any time,
      including (for texts) by replying “STOP”. I understand any Renewal by
      Andersen calls may be recorded. Renewal by Andersen collects certain
      categories of personal information and uses this information in various
      ways, including order fulfillment and providing product information and
      services, as further described in our Privacy Policy. I agree to Renewal
      by Andersen’s <a :href="privacyUrl">Privacy Policy</a> and
      <a :href="termsUrl">Terms and Conditions</a>. Message and data rates
      apply. California residents, click
      <a href="https://www.rbadataprivacy.com/California">here</a> for more
      information about the information we collect.
    </small>
    <small
      v-else
      class="form-fine-print"
    >
      I’d like to learn more about Renewal by Andersen windows. Please contact
      me at the phone number I listed above to schedule a convenient day and
      time for an in-home price quote. I am submitting this form so you can
      contact me and keep me informed about your products, services and when
      they go on sale. I understand that I do not need to submit this form to
      schedule an in-home price quote. Instead I may call your service center at
      the number on this page. I understand that all calls are recorded. Renewal
      by Andersen collects certain categories of personal information and uses
      this information in various ways, including order fulfillment and
      providing product information and service to you. For more information
      visit our <a :href="privacyUrl">privacy&nbsp;policy</a>.
    </small>
  </form>
</template>

<style scoped>
.form-error {
  margin-top: 8px;
}

.btn.disabled {
  background: #c4c4c4;
  cursor: not-allowed;
}
</style>

<script>
import axios from 'axios'
import {
  required,
  email,
  requiredIf,
  minLength,
  maxLength,
  numeric,
} from 'vuelidate/lib/validators'
import { TheMask } from 'vue-the-mask'
import LoadingGif from './LoadingGif.vue'

export default {
  components: {
    TheMask,
    LoadingGif,
  },
  props: {
    formTop: {
      type: Object,
      default: () => {},
    },
    noQuiz: {
      type: Boolean,
      default: false,
    },
    heroFormTitle: {
      type: String,
      default: '',
    },
    submitText: {
      type: String,
      default: '',
    },
    path: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      formTopCopy: this.formTop || {},
      isListicle: !!this.$quizData.listicle,
      isRebrand: this.$quizData.rebrand,
      isReskinnedPage: this.$quizData.reskin,
      formspreeID: this.$quizData.formspree_id,
      isSih: this.$quizData.sih_graduate,
      isFormBelowMultiPage:
        this.$quizData.is_multi_page_embed && !this.$quizData.is_form_results,
      isCanada: !!(
        this.path.includes('toronto')
        || this.path.includes('corp/national/ca')
        || this.path.includes('canada')
      ),
      phoneNumber: gon.phone_number || '000-000-0000',
      form: {
        email: '',
        firstName: '',
        lastName: '',
        postalCode: '',
        zipcode: '',
        phoneNumber: '',
      },
      errors: {
        email: false,
        firstName: false,
        lastName: false,
        postalCode: false,
        zipcode: false,
        phoneNumber: false,
      },
      data: {},
      userResponses: '',
      formError: false,
      errorMessage: '',
      isSubmitting: false,
      email_tooltip: false,
      phone_tooltip: false,
      zip_tooltip: false,
      onlineScheduling: false,
      submissionId: null,
      hatchDivision: null,
      recaptchaVerified: false,
      apiResponse: []
    }
  },
  validations: {
    form: {
      email: { email, required },
      firstName: { required },
      lastName: { required },
      postalCode: {
        maxLength: maxLength(7),
        minLength: minLength(7),
        requiredIf: requiredIf(function () {
          return this.isCanada
        }),
      },
      zipcode: {
        numeric,
        maxLength: maxLength(5),
        minLength: minLength(5),
        requiredIf: requiredIf(function () {
          return !this.isCanada
        }),
      },
      phoneNumber: {
        required,
        minLength: minLength(14),
      },
    },
  },
  computed: {
    byPassRecaptcha() {
      return this.path.includes('/moore') && !this.path.includes('/rc')
    },
    isDisabled() {
      return this.isSubmitting || this.$v.$error || this.$v.$anyError || !this.recaptchaVerified
    },
    privacyUrl() {
      if (this.path.includes('moore/')) {
        return 'http://www.rbadataprivacy.com/'
      }
      return 'https://www.andersenwindows.com/support/privacy'
    },
    termsUrl() {
      return 'https://www.renewalbyandersen.com/about/terms-of-use'
    },
    apiUrl() {
      if (this.path.includes('eastern-ny')) {
        return 'https://hooks.zapier.com/hooks/catch/10315546/3bpthgz/'
      }

      if (
        window.location.hostname === 'localhost'
        || this.path.includes('_stg')
      ) {
        return 'https://fc2-stg.thesistestingapps.com/eaaa3934/lead-form-1'
      }
      return 'https://fc2.thesistestingapps.com/eaaa3934/lead-form-1'
    },
    quizResults() {
      return this.$store.getters.RESULTS
    },
    submitButtonText() {
      if (this.isEsler && !this.path.includes('carolinas/search/reviews')) {
        return 'SCHEDULE MY FREE CONSULTATION'
      }
      return 'GET MY FREE CONSULTATION'
    },
    rbaBreakdown() {
      const consolidatedUrls = [
        '/moore/gen',
        '/moore/midwest/gen',
        'tx-ok/search/doors',
        'tx-ok/search/windows',
        'doors',
        'windows',
      ]
      if (this.$quizData.rba_breakdown) return this.$quizData.rba_breakdown
      if (this.$quizData.is_tv_lp) return this.$tvData.breakdown

      if (this.path.includes('/101')) {
        return 'ThesisPPC_Google_Brand'
      }
      if (this.path.includes('/102')) {
        return 'ThesisPPC_Google_NB'
      }
      if (this.path.includes('/201')) {
        return 'ThesisPPC_Bing_Brand'
      }
      if (this.path.includes('/202')) {
        return 'ThesisPPC_Bing_NB'
      }
      if (
        this.path.includes('esler/gen/nextdoor')
        || this.path.includes('pittsburgh/nextdoor')
        || this.path.includes('corp/gen/nextdoor')
      ) {
        return 'Thesis Nextdoor'
      }
      if (
        this.path.includes('/tt')
        || this.path.includes('/tiktok')
        || this.path.includes('/oregon')
      ) {
        return 'Thesis TikTok'
      }
      if (this.path.includes('/pch')) return 'PCH Email'
      if (this.path.includes('corp/national/nextdoor')) {
        return 'national_nextdoor'
      }
      if (this.path.includes('nextdoor')) return 'Next Door'
      if (this.path.includes('/corp/national/t')) return 'Twitter'
      if (this.path.includes('/esler/video')) return 'ConvergeCTV'
      if (this.path.includes('/esler/audio')) return 'ConvergeStreamingAudio'
      if (this.path.includes('/moore/comp')) return 'Calusa Marketing'
      if (this.path.includes('podcasts')) return 'Podcast Within'
      if (this.path.includes('/email')) return 'Email Test'
      if (this.path.includes('/discovery')) return 'ThesisGoogleDiscovery'
      if (this.path.includes('/esler/search')) return 'ThesisPPC'
      if (this.path.includes('/esler/display/windows')) {
        return 'ThesisDisplay'
      }
      if (this.path.includes('/esler/gen/audio/1')) { return 'Barrington Terrestrial - Landing Page' }
      if (this.path.includes('/audio/1')) return 'Barrington Terrestrial'
      if (this.path.includes('/audio/2') || this.path.includes('/audio/3')) return 'Barrington Digital'
      if (this.path.includes('/scn')) return 'ThesisNativeContent'
      if (this.path.includes('/esler/pm/windows')) return 'ThesisPMAX'
      if (
        (this.isEsler
          && this.path.includes('/gen')
          && !this.path.includes('taboola'))
        || consolidatedUrls.includes(this.path)
        || this.path.includes('/westchester')
      ) {
        return 'Hubble Facebook Consolidated'
      }

      if (
        this.path.includes('moore/midwest/gen')
        || this.path.includes('moore/metro/gen')
      ) {
        return 'Hubble Facebook Consolidated'
      }
      if (
        this.path.includes('moore/midwest/ded')
        || this.path.includes('moore/metro/ded')
      ) {
        return 'Hubble Facebook'
      }

      if (this.path.includes('/mlb')) {
        return 'MLB Email'
      }

      const urlParams = new URLSearchParams(window.location.search)
      const isEslerSearch = this.path.includes('/esler/search')

      if (isEslerSearch && urlParams.get('utm_medium')) {
        if (urlParams.get('utm_medium').toLowerCase() === 'thesis-ppc') {
          return 'ThesisPPC'
        }
      }

      if (urlParams.get('utm_source')) {
        if (urlParams.get('utm_source').toLowerCase() === 'pinterest') {
          return 'Hubble Pinterest'
        }
        if (urlParams.get('utm_source').toLowerCase() === 'google') {
          return 'Hubble Google'
        }
        if (
          urlParams.get('utm_source').toLowerCase() === 'youtube'
          && this.isEsler
        ) {
          return 'Thesis Youtube'
        }
      }

      return 'Hubble Facebook'
    },
    rbaSource() {
      if (this.$quizData.rba_source) return this.$quizData.rba_source
      if (this.$quizData.is_tv_lp) return this.$tvData.source
      if (this.path.includes('corp/national/nextdoor')) return 'social_media'
      if (this.path.includes('podcasts')) {
        return 'Radio'
      }
      if (
        this.path.includes('/esler/video')
        || this.path.includes('/esler/audio')
        || this.path.includes('/moore/comp')
        || this.path.includes('/pch')
        || this.path.includes('/mlb')
        || this.rbaBreakdown === 'Thesis Youtube'
      ) {
        return 'Digital Media'
      }
      if (
        this.path.includes('/discovery')
        || this.path.includes('/esler/pm/windows')
        || this.path.includes('/101')
        || this.path.includes('/102')
        || this.path.includes('/201')
        || this.path.includes('/202')
      ) {
        return 'PPC Web'
      }
      if (this.path.includes('/email')) {
        return 'Direct Email'
      }
      if (this.path.includes('search') && this.rbaBreakdown === 'ThesisPPC') {
        return 'PPC Web'
      }
      if (this.path.includes('/esler/display/windows')) return 'PPC Web'
      if (this.path.includes('/audio')) return 'Radio'
      return 'Social Media'
    },
    isEsler() {
      return !!this.path.includes('esler')
    },
    isMoore() {
      return !!this.path.includes('moore')
    },
  },
  mounted() {
    if (
      window.location.href.includes('localhost')
      || window.location.href.includes('_stg')
    ) {
      console.log(`Source:  ${this.$quizData.rba_source}`)
      console.log(`BREAKDOWN:  ${this.$quizData.rba_breakdown}`)
    }
    const searchParams = new URLSearchParams(window.location.search)
    // Iterating the search parameters
    const params = {}
    for (const p of searchParams) {
      params[p[0]] = p[1]
    }

    if (
      params.rba_market == 'canada'
      || params.rba_market == 'toronto'
      || params.rba_market == 'british-columbia'
    ) {
      this.isCanada = true
    }
    setTimeout(() => {
      this.verifyUser()
    }, 2500)
  },
  methods: {
    async submitForm(e) {
      e.preventDefault()

      const blockedEmails = [
        'sdfwedw@gmail.com',
        'tornanirkhalighor@gmail.com',
        'bodixos323@wanbeiz.com',
        'karen-xdhub69@uma3.be'
      ]

      if (blockedEmails.includes(this.form.email)) return

      this.formError = false

      if ((this.isFormBelowMultiPage && !this.$store.getters.ONE_PAGE_VALID) && !this.noQuiz) {
        this.formError = true
        this.errorMessage = 'Please complete all quiz questions before submitting.'

        return
      }

      if (this.validateForm()) {
        if (this.isCanada) { this.form.postalCode = this.form.postalCode.toUpperCase() }

        this.isSubmitting = true
        this.createDataObject()
        this.sortResultsAndAddParams()
        this.sendDataToApi()

        // audio conversion events
        if (this.path.includes('audio')) {
          const audioPixel = document.createElement('img')
          audioPixel.style.display = 'none'
          if (this.path.includes('esler')) {
            audioPixel.src = 'https://audiology-564-adswizz.attribution.adswizz.com/fire?pixelId=94c8710d-8f1c-4f10-bdcb-4592fcdeeb01&type=sitevisit&subtype=FormSubmission1&aw_0_req.gdpr=true&redirectURL=aHR0cHM6Ly9waXhlbC50YXBhZC5jb20vaWRzeW5jL2V4L3JlY2VpdmU_cGFydG5lcl9pZD0yOTk0JjwjaWYgcmVxdWVzdC5saXN0ZW5lcklkP21hdGNoZXMoJ1swLTlhLWZdezh9LVswLTlhLWZdezR9LVswLTlhLWZdezR9LVswLTlhLWZdezR9LVswLTlhLWZdezEyfScpPnBhcnRuZXJfdHlwZWRfZGlkPSU3QiUyMkhBUkRXQVJFX0FORFJPSURfQURfSUQlMjIlM0ElMjIke3JlcXVlc3QubGlzdGVuZXJJZH0lMjIlN0Q8I2Vsc2VpZiByZXF1ZXN0Lmxpc3RlbmVySWQ_bWF0Y2hlcygnWzAtOUEtRl17OH0tWzAtOUEtRl17NH0tWzAtOUEtRl17NH0tWzAtOUEtRl17NH0tWzAtOUEtRl17MTJ9Jyk-cGFydG5lcl90eXBlZF9kaWQ9JTdCJTIySEFSRFdBUkVfSURGQSUyMiUzQSUyMiR7cmVxdWVzdC5saXN0ZW5lcklkfSUyMiU3RDwjZWxzZT5wYXJ0bmVyX2RldmljZV9pZD0ke3JlcXVlc3QubGlzdGVuZXJJZCF9PC8jaWY-'
          } else if (this.path.includes('moore')) {
            audioPixel.src = 'https://audiology-564-adswizz.attribution.adswizz.com/fire?pixelId=8e36fd32-e850-4cd9-b130-b4bb6154b26a&type=sitevisit&subtype=FormSubmission2&aw_0_req.gdpr=true&redirectURL=aHR0cHM6Ly9waXhlbC50YXBhZC5jb20vaWRzeW5jL2V4L3JlY2VpdmU_cGFydG5lcl9pZD0yOTk0JjwjaWYgcmVxdWVzdC5saXN0ZW5lcklkP21hdGNoZXMoJ1swLTlhLWZdezh9LVswLTlhLWZdezR9LVswLTlhLWZdezR9LVswLTlhLWZdezR9LVswLTlhLWZdezEyfScpPnBhcnRuZXJfdHlwZWRfZGlkPSU3QiUyMkhBUkRXQVJFX0FORFJPSURfQURfSUQlMjIlM0ElMjIke3JlcXVlc3QubGlzdGVuZXJJZH0lMjIlN0Q8I2Vsc2VpZiByZXF1ZXN0Lmxpc3RlbmVySWQ_bWF0Y2hlcygnWzAtOUEtRl17OH0tWzAtOUEtRl17NH0tWzAtOUEtRl17NH0tWzAtOUEtRl17NH0tWzAtOUEtRl17MTJ9Jyk-cGFydG5lcl90eXBlZF9kaWQ9JTdCJTIySEFSRFdBUkVfSURGQSUyMiUzQSUyMiR7cmVxdWVzdC5saXN0ZW5lcklkfSUyMiU3RDwjZWxzZT5wYXJ0bmVyX2RldmljZV9pZD0ke3JlcXVlc3QubGlzdGVuZXJJZCF9PC8jaWY-'
          }

          document.body.appendChild(audioPixel)
        }

        if (
          this.path.includes('nextdoor')
          || this.path.includes('corp')
          || this.path.includes('/oregon/social')
        ) {
          if (typeof ndp !== 'undefined') {
            ndp('track', 'CONVERSION')
          }
        }
        if (
          this.path.includes('/tt')
          || this.path.includes('/tiktok')
          || this.path.includes('/oregon/social')
        ) {
          if (typeof ttq !== 'undefined') {
            ttq.identify({
              email: this.form.email,
              phone_number: `+1${this.form.phoneNumber}`,
            })

            ttq.track('SubmitForm')
          }
        }
        if (typeof gtag_report_conversion !== 'undefined') {
          gtag_report_conversion()
        }
        if (typeof window.uet_report_conversion !== 'undefined') {
          window.uet_report_conversion()
        }
        if (window.dstillery) {
          // conversion script
          const dstilleryConversionEvent = document.createElement('script')
          dstilleryConversionEvent.src = '//action.dstillery.com/orbserv/nsjs?adv=cl167889424122475&ns=8035&nc=Renewal_by_Anderson_Form_Fill&ncv=43&dstOrderId=[OrderId]&dstOrderAmount=[OrderAmount]'
          dstilleryConversionEvent.type = 'text/javascript'
          // conversion noscript
          const dstilleryConversionEventNoScript = document.createElement('noscript')
          const dstilleryConversionEventNoScriptImg = document.createElement('noscript')
          dstilleryConversionEventNoScriptImg.src = '//action.dstillery.com/orbserv/nspix?adv=cl167889424122475&ns=8035&nc=Renewal_by_Anderson_Form_Fill&ncv=43&dstOrderId=[OrderId]&dstOrderAmount=[OrderAmount]'
          dstilleryConversionEventNoScriptImg.style.display = 'none'
          dstilleryConversionEventNoScript.appendChild(dstilleryConversionEventNoScriptImg)

          document.body.appendChild(dstilleryConversionEvent)
          document.body.appendChild(dstilleryConversionEventNoScript)
        }
        if (typeof rdt !== 'undefined') {
          if (this.path.includes('/oregon/social')) {
            rdt('track', 'Lead', {
              currency: 'USD',
              transactionId: '12345678',
              value: 100,
            })
          }
        }
      }
    },
    validateForm() {
      this.$v.$touch()
      if (this.$v.$anyError) {
        const { form } = this

        // eslint-disable-next-line no-unused-expressions
        this.isCanada ? delete form.zipcode : delete form.postalCode
        const keys = Object.keys(form)
        this.clearErrors(keys)
        // eslint-disable-next-line no-restricted-syntax
        keys.forEach(key => {
          if (this.$v.form[key] && this.$v.form[key].$anyError) {
            this.errors[key] = true
          }
        })
      } else if (this.recaptchaVerified === false) {
        this.formError = true
        this.errorMessage = 'Please verify that you are not a robot.'
      } else {
        return true
      }
      return false
    },
    clearErrors(keys) {
      keys.forEach(key => {
        this.errors[key] = false
      })
    },
    createDataObject() {
      this.data = {
        EmailAddress: this.form.email || 'null',
        FirstName: this.form.firstName,
        LastName: this.form.lastName,
        Zipcode: this.form.zipcode || this.form.postalCode,
        'Phone Number': this.form.phoneNumber,
        'Form Type': 2,
        Sender: 'Thesis',
        RbASource: this.rbaSource,
        RbABreakdown: this.rbaBreakdown,
        CallingRights: 'Y',
        rba_1: '',
        rba_2: '',
        rba_3: '',
        rba_4: '',
        rba_5: '',
      }

      const gclid = new URLSearchParams(window.location.search).get('gclid')
      if (gclid) this.data.rba_6 = `${gclid}`
    },
    sortResultsAndAddParams() {
      let run = false
      const resultsArray = []
      const quizAnswers = this.quizResults

      // Visual quiz needs spans removed from quiz answer html
      // otherwise rba api will send error on submit
      const isVisualQuiz = this.$quizData.sanitize_quiz_answers
      if (!run) {
        quizAnswers.forEach(result => {
          if (result === null) return
          const question = result.label
            .split('?')[0]
            .replace('&nbsp;', ' ')
            .replace("<br class='d-sm-up'>", '')
          if (Array.isArray(result.content)) {
            let answers = ''

            if (isVisualQuiz) {
              // Visual quiz multi-choice
              result.content.forEach(word => {
                // Split on end of image span
                const split = word.selection.split('</span>')

                // Add what's after span tag or regular content
                answers += `${split[1] || split[0]}, `
              })
            } else {
              result.content.forEach(word => {
                answers += `${word.selection}, `
              })
            }

            resultsArray.push(` ${question}?: ${answers.slice(0, -2)}`)
          } else if (isVisualQuiz) {
            // Visual quiz non-multi-choice
            const content = result.content.split('</span>')
            resultsArray.push(` ${question}?: ${content[1] || content[0]}`)
          } else {
            resultsArray.push(` ${question}?: ${result.content}`)
          }
        })
        this.data.WindowsProblems = resultsArray.join(' |')
        this.addUTMparams()
        run = true
      }
    },
    removeStgFromPathname(pathname) {
      if (pathname.includes('/_stg/')) {
        // Split the pathname into an array of path segments
        const pathSegments = pathname.split('/')
        // Find the index of the '_stg' segment
        const stgIndex = pathSegments.findIndex(segment => segment === '_stg')
        // Remove the '_stg' segment and its following segment (the digits)
        pathSegments.splice(stgIndex, 2)
        // Join the path segments back into a string
        pathname = pathSegments.join('/')
      }
      return pathname
    },
    isOneSourcePage() {
      const oneSourcePaths = [
        '/esler/social',
        '/moore/social',
      ]
      // return false if path is not in paths
      if (!oneSourcePaths.includes(this.removeStgFromPathname(window.location.pathname))) return false
      const queryParams = window.location.search.slice(1).split('&')
      const paramMap = new Map()
      queryParams.forEach(param => {
        const [key, value] = param.split('=')
        paramMap.set(key, value)
      })
      // check is params are present for onesource form submit
      if (
        paramMap.get('rba_channel') === 'ott'
          && ((paramMap.get('rba_market') === 'esler' || paramMap.get('rba_market') === 'moore'))
      ) {
        return true
      }
      return false
    },
    createSchedulingData() {
      const schedulingData = new URLSearchParams()
      schedulingData.append('FirstName', this.form.firstName)
      schedulingData.append('LastName', this.form.lastName)
      schedulingData.append('EmailAddress', this.form.email)
      schedulingData.append('PhoneNumber', this.form.phoneNumber)
      schedulingData.append('Zipcode', this.form.zipcode || this.form.postalCode)
      schedulingData.append('Sender', 'Thesis-BMG')
      schedulingData.append('RbASource', this.rbaSource)
      schedulingData.append('RbABreakdown', this.rbaBreakdown)
      schedulingData.append('FormType', 2)
      schedulingData.append('OnlineSchedulingSubmission', 'primary')

      // utm params
      schedulingData.append('rba_1', this.data.rba_1)
      schedulingData.append('rba_2', this.data.rba_2)
      schedulingData.append('rba_3', this.data.rba_3)
      schedulingData.append('rba_4', this.data.rba_4)
      schedulingData.append('rba_5', this.data.rba_5)
      if (this.data.rba_6) { schedulingData.append('rba_6', this.data.rba_6) }

      return schedulingData
    },
    async enabledPlusSubmission() {
      // post request
      let url = ''
      let headers = {}

      if (this.path.includes('_stg')) {
        url = 'https://test.renewalbyandersen.com/api/sitecore/featureforms/submitform'
        headers = {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: '611EB4FF-92F7-4883-A757-EC8572BCF174',
        }
      } else {
        url = 'https://www.renewalbyandersen.com/api/sitecore/featureforms/submitform'
        headers = {
          'Content-Type': 'application/x-www-form-urlencoded',
          Authorization: 'F393C372-28C2-401C-BECE-07C3192BF5E9',
        }
      }

      const schedulingData = this.createSchedulingData()

      let enabledRes = await fetch(url, { method: 'POST', headers, body: schedulingData })
      if (!enabledRes.ok) {
        this.errorMessage = this.isCanada
            ? 'Renewal by Andersen is unavailable in your area.'
            : e.message.split(':').slice(-1)[0].trim()
        this.formError = true
        this.isSubmitting = false
        window.rba.tracking.contactFormError(this.errorMessage)
        return
      }

      let enabledJSON = await enabledRes.json()
      this.apiResponse.push(enabledJSON)

      let impactRes = await this.bmgImpactSubmission(schedulingData)
      if (!impactRes.ok) console.log(impactRes.statusText)

      this.onlineScheduling = enabledJSON.isOnlineSchedulingEligible || false
      this.submissionId = enabledJSON.submissionId || null
      const resultId = enabledJSON.resultid || null

      let hatchDivision = null
      if (this.path.includes('moore')) hatchDivision = 'moore'
      else if (this.path.includes('/esler/search')) hatchDivision = 'esler_search'
      else if (this.path.includes('/esler/social') || this.path.includes('/esler/tx-ok')) hatchDivision = 'esler_social'
      else if (this.path.includes('/eastern-ny')) hatchDivision = 'eastern_ny'
      else if (this.path.includes('rbawa')) hatchDivision = 'rbawa'

      this.hatchDivision = hatchDivision
      
      if (this.onlineScheduling === false) {
        if (this.hatchDivision != null) {
          const division = this.hatchDivision

          let hatchRes = await fetch('https://ambassador.thesistestingapps.com/rba/hatch', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ ...this.data, division })
          })
          if (!hatchRes.ok) {
            const errorMessage = 'Hatch API integration failed to post the appropriate data'
            window.rba.tracking.contactFormError(errorMessage)
          }
        }
      }

      // pass new ID to formSubmit
      await this.formSubmitComplete(resultId).catch((e) => console.log(e))
    },
    bmgImpactSubmission(data) {
      return fetch('https://ambassador.thesistestingapps.com/rba/impact', {
        method: 'POST',
        body: data,
      })
    },
    oneSourceSubmission(data) {
      return fetch('https://ambassador.thesistestingapps.com/rba/one-source', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
    },
    async sendDataToApi() {
      if (this.path.includes('eastern-ny')) {
        const formSubmit = axios.create({
          transformRequest: data => data.data,
          headers: {},
        })

        await formSubmit
          .post('https://hooks.zapier.com/hooks/catch/10315546/3bpthgz/', {
            data: JSON.stringify(this.data),
          })
      }
      const oneSourceData = {
        name: `${this.data.FirstName} ${this.data.LastName}`,
        callerId: this.data['Phone Number'],
        email: this.data.EmailAddress,
      }
      if (this.isOneSourcePage()) {
        await this.oneSourceSubmission(oneSourceData).catch((e) => console.log(e))
      }
      await this.enabledPlusSubmission().catch((e) => console.log(e))
    },
    getDivision() {
      if (this.path.includes('esler')) return 'esler'
      if (this.path.includes('moore')) return 'moore'
      if (this.path.includes('corp')) return 'corp'
      if (this.path.includes('oregon')) return 'oregon'
      if (this.path.includes('rbawa')) return 'rbawa'
      if (this.path.includes('eastern_ny')) return 'eastern_ny'
      return 'esler'
    },
    redirectToThankYouPage(uuid) {
      if (this.onlineScheduling && this.submissionId !== null) {
        window.location = `https://offers.renewalbyandersen.com/online-scheduling/?submission_id=${this.submissionId}&zipcode=${this.isCanada ? this.form.postalCode : this.form.zipcode}&division=${this.getDivision()}${uuid ? `&uuid=${uuid}` : ''}${this.hatchDivision ? `&hatchDivision=${this.hatchDivision}` : ''}${this.path.includes('esler/search') ? '&channelAuto=true' : ''}${this.isCanada ? '&canada=true' : ''}`
        return
      }

      const searchParams = new URLSearchParams(window.location.search)
      // Iterating the search parameters
      const params = {}
      for (const p of searchParams) {
        params[p[0]] = p[1]
      }
      if (this.path.includes('corp')) {
        //
        if (this.path.includes('boston') || params.rba_market == 'boston') { window.location.href = '/corp/thank-you-boston' } else if (
          this.path.includes('chicago')
          || params.rba_market == 'chicago'
        ) { window.location.href = '/corp/thank-you-chicago' } else if (
          this.path.includes('cleveland')
          || params.rba_market == 'cleveland'
        ) { window.location.href = '/corp/thank-you-cleveland' } else if (
          this.path.includes('detroit')
          || params.rba_market == 'detroit'
        ) { window.location.href = '/corp/thank-you-detroit' } else if (
          this.path.includes('pittsburgh')
          || params.rba_market == 'pittsburgh'
        ) { window.location.href = '/corp/thank-you-pittsburgh' } else if (
          this.path.includes('capital')
          || params.rba_market == 'capital'
        ) { window.location.href = '/corp/thank-you-capital' } else if (this.path.includes('la') || params.rba_market == 'la') { window.location.href = '/corp/thank-you-la' } else if (this.path.includes('orange') || params.rba_market == 'orange') { window.location.href = '/corp/thank-you-orange' } else if (this.path.includes('twin') || params.rba_market == 'twin') { window.location.href = '/corp/thank-you-twin' } else if (
          this.path.includes('national/ca')
          || params.rba_market == 'canada'
        ) { window.location.href = '/corp/thank-you-national-ca' } else if (
          this.path.includes('national')
          || params.rba_market == 'national'
          || (params.rba_channel == 'twitter' && params.rba_market == 'coro')
        ) { window.location.href = '/corp/thank-you-national' } else if (
          this.path.includes('michigan')
          || params.rba_market == 'michigan'
        ) { window.location.href = '/corp/thank-you-michigan' } else if (this.path.includes('gen') || params.rba_market == 'coro') { window.location.href = '/corp/thank-you-gen' } else { window.location.href = '/corp/thank-you-gen' }
        //
      } else if (this.path.includes('moore')) {
        //
        if (
          this.path.includes('midwest')
          || params.rba_market == 'mw'
          || params.rba_market == 'midwest'
        ) { window.location.href = '/moore/thank-you-midwest' } else if (this.path.includes('sf') || params.rba_market == 'sf') { window.location.href = '/moore/thank-you-sf' } else if (
          this.path.includes('toronto')
          || params.rba_market == 'toronto'
        ) { window.location.href = '/moore/thank-you-toronto' } else if (this.path.includes('/atl') || params.rba_market == 'atl') { window.location.href = '/moore/thank-you-atl' } else if (this.path.includes('/pch') || params.rba_campaign == 'pch') { window.location.href = '/moore/thank-you-gen?pch=true' } else window.location.href = '/moore/thank-you-gen'
        //
      } else if (this.path.includes('eastern-ny')) {
        window.location.href = '/eastern-ny/thank-you-gen'
      } else if (this.path.includes('podcasts')) {
        window.location.href = '/thank-you'
      } else if (this.path.includes('/san-diego')) {
        window.location.href = '/san-diego/thank-you'
      } else if (this.path.includes('/oregon')) {
        window.location.href = '/oregon/thank-you'
      } else if (this.path.includes('/rbawa')) {
        window.location.href = '/rbawa/thank-you'
      } else {
        window.location.href = '/esler/thank-you'
      }
    },
    addUTMparams() {
      const urlParams = new URLSearchParams(window.location.search)
      // eslint-disable-next-line no-restricted-syntax
      for (const key of urlParams.keys()) {
        switch (key) {
          case 'utm_campaign':
            this.data.rba_1 = urlParams.get('utm_campaign')
            break
          case 'utm_source':
            this.data.rba_2 = urlParams.get('utm_source')
            break
          case 'utm_medium':
            this.data.rba_3 = urlParams.get('utm_medium')
            break
          case 'utm_content':
            this.data.rba_4 = urlParams.get('utm_content')
            break
          case 'utm_term':
            this.data.rba_5 = urlParams.get('utm_term')
            break
          default:
            // eslint-disable-next-line no-console
            console.log('unpermitted param')
        }
      }
    },
    async postToFormSpree() {
      return axios({
        url: `https://formspree.io/${this.formspreeID}`,
        method: 'POST',
        headers: {
          Accept: 'application/json',
        },
        data: this.data,
      })
    },
    createDBRecord() {
      return fetch('https://ambassador.thesistestingapps.com/rba/leadData', {
        method: 'POST',
        body: JSON.stringify({...this.data}),
      })
    },
    async formSubmitComplete(resultId) {
      window.rba.tracking.contactFormSuccess(this.form, resultId)
      if (typeof window.uet_report_conversion !== 'undefined') {
        window.uet_report_conversion()
      }
      if (this.formspreeID) {
        await this.postToFormSpree().catch((e) => console.log(e))
      }

      let dbRes = await this.createDBRecord()
      let dbJSON = await dbRes.json()

      await fetch('https://ambassador.thesistestingapps.com/rba/apiData', {
        method: 'POST',
        body: JSON.stringify({...this.apiResponse, uuid: dbJSON.uuid}),
      });

      setTimeout(() => this.redirectToThankYouPage(dbJSON.uuid), 4000)
    },
    toggleTooltip(tooltip) {
      // console.log(this[tooltip])
      this[tooltip] = !this[tooltip]
      // this.$refs[tooltip].focus();
      this.$nextTick(() => {
        this.$el.querySelector(`.${tooltip}`).focus()
      })
    },
    hideTooltip(tooltip) {
      this[tooltip] = false
      this.$nextTick(() => {
        this.$el.querySelector(`.${tooltip}`).blur()
      })
    },
    verifyUser() {
      const self = this

      if(!this.byPassRecaptcha) {
        grecaptcha.render('grecaptcha', {
          sitekey: '6Lf0JTonAAAAAN1Pfsg3yGJqOw5JUYQ44Lw5d3he',
          callback: self.recaptchaValid,
        })
      } else {
        this.recaptchaVerified = true
      }
    },
    recaptchaValid() {
      this.recaptchaVerified = true
    },
  },
}
</script>
