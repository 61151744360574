<template>
  <section
    :class="['mini-quiz-intro', content.classes]"
  >
    <div class="container">
      <div class="text-section">
        <h2
          v-if="content.title"
          class="title"
          v-html="content.title"
        />

        <a
          v-if="content.scroll_image && content.cta_link"
          v-scroll-to="{
            el: content.cta_link,
            offset: -80,
            duration: 500,
          }"
          :href="content.cta_link"
          :data-trackable="content.tracking || 'quiz'"
          :data-interaction="content.action || 'cta'"
          class="desktop-scroll"
        >
          <img
            :src="content.scroll_image"
            alt="click to scroll"
          />
        </a>
      </div>

      <div class="media-section">
        <video
          v-if="content.video"
          playsinline autoplay loop muted
        >
          <source :src="content.video" />
        </video>
      </div>

      <a
        v-if="content.scroll_image && content.cta_link"
        v-scroll-to="{
          el: content.cta_link,
          offset: -80,
          duration: 500,
        }"
        :href="content.cta_link"
        :data-trackable="content.tracking || 'quiz'"
        :data-interaction="content.action || 'cta'"
        class="mobile-scroll"
      >
        <img
          :src="content.scroll_image"
          alt="click to scroll"
        />
      </a>
    </div>
  </section>
</template>

<script>
export default {
  props: {
    content: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
