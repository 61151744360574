var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{class:['mini-quiz-intro', _vm.content.classes]},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"text-section"},[(_vm.content.title)?_c('h2',{staticClass:"title",domProps:{"innerHTML":_vm._s(_vm.content.title)}}):_vm._e(),_vm._v(" "),(_vm.content.scroll_image && _vm.content.cta_link)?_c('a',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
          el: _vm.content.cta_link,
          offset: -80,
          duration: 500,
        }),expression:"{\n          el: content.cta_link,\n          offset: -80,\n          duration: 500,\n        }"}],staticClass:"desktop-scroll",attrs:{"href":_vm.content.cta_link,"data-trackable":_vm.content.tracking || 'quiz',"data-interaction":_vm.content.action || 'cta'}},[_c('img',{attrs:{"src":_vm.content.scroll_image,"alt":"click to scroll"}})]):_vm._e()]),_vm._v(" "),_c('div',{staticClass:"media-section"},[(_vm.content.video)?_c('video',{attrs:{"playsinline":"","autoplay":"","loop":"","muted":""},domProps:{"muted":true}},[_c('source',{attrs:{"src":_vm.content.video}})]):_vm._e()]),_vm._v(" "),(_vm.content.scroll_image && _vm.content.cta_link)?_c('a',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
        el: _vm.content.cta_link,
        offset: -80,
        duration: 500,
      }),expression:"{\n        el: content.cta_link,\n        offset: -80,\n        duration: 500,\n      }"}],staticClass:"mobile-scroll",attrs:{"href":_vm.content.cta_link,"data-trackable":_vm.content.tracking || 'quiz',"data-interaction":_vm.content.action || 'cta'}},[_c('img',{attrs:{"src":_vm.content.scroll_image,"alt":"click to scroll"}})]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }