<template>
  <div class="list-block">
    <ul>
      <li
        v-for="(item, index) in items"
        :key="index"
        :class="{ 'icon-list-item': hasIcon || !!item.icon }"
      >
        <figure
          v-if="hasIcon || item.icon"
          class="list-icon"
        >
          <img :src="item.icon ? importImage(item.icon) : defaultIcon">
        </figure>
        <span
          class="list-text"
          v-html="item.text"
        />
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      items: this.content.items,
      icon: this.content.icon,
      hasIcon: !!this.content.icon,
    }
  },
  computed: {
    defaultIcon() {
      if (this.hasIcon) return this.importImage(this.icon)
      return ''
    },
  },
  methods: {
    importImage(src) {
      return require(`../images/icons/${src}`)
    },
  },
}
</script>
