<template>
  <div
    class="static-hero-intro"
    style="display: contents"
  >
    <StaticHero :content="content.hero" />
    <Banner
      v-if="content.banner"
      :content="content.banner"
    />
    <DiscountCallout v-if="showDiscountCallout" />
  </div>
</template>

<script>
import Banner from '../components/Banner.vue'
import DiscountCallout from '../components/DiscountCallout.vue'
import StaticHero from '../components/StaticHero.vue'

export default {
  components: {
    Banner,
    DiscountCallout,
    StaticHero,
  },
  data() {
    return {
      content: this.$quizData.staticHeroIntro,
      showDiscountCallout: !!this.$quizData.discount,
    }
  },
}
</script>
