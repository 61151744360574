<template>
  <div
    v-if="content.image_blocks"
    id="discount-callout"
    :class="`discount-callout multi-offer-${content.image_blocks.length}`"
  >
    <div class="container">
      <div class="callout-content">
        <div
          v-for="(item, idx) in content.image_blocks"
          :key="`discount-${idx}`"
          class="image-text-pair"
        >
          <figure
            v-if="item.image"
            class="media-block"
          >
            <picture>
              <img :src="importImage(item.image)">
            </picture>
          </figure>
          <h3
            v-if="item.title"
            v-html="item.title"
          />
        </div>
      </div>
      <div class="title-block">
        <h2
          v-if="content.title"
          v-html="content.title"
        />
        <p
          v-if="content.text"
          v-html="content.text"
        />
        <p
          v-if="content.expire_text"
          class="expire-text"
          v-html="content.expire_text"
        />
      </div>
    </div>
  </div>
  <div
    v-else
    id="discount-callout"
    class="discount-callout"
  >
    <div class="container">
      <figure
        v-if="content.image"
        class="media-block"
      >
        <picture>
          <img :src="importImage(content.image)">
        </picture>
      </figure>
      <div class="title-block">
        <h2
          v-if="content.title"
          v-html="content.title"
        />
        <p
          v-if="content.text"
          v-html="content.text"
        />
        <p
          v-if="content.expire_text"
          class="expire-text"
          v-html="content.expire_text"
        />
      </div>
    </div>
    <small class='discount-disclaimer-small'>*Interest is billed during promo period but will be waived if the amount financed is paid in full before promo period expires.</small>
  </div>
</template>

<script>
import data from '../data/discountCallout.json'

export default {
  data() {
    const offerKey = this.$quizData.discount
    const content = offerKey ? data[offerKey] : data.default

    return {
      content,
    }
  },
  methods: {
    importImage(src) {
      return require(`../images/discount-callout/${src}`)
    },
  },
}
</script>
