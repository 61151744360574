<template>
  <div :class="classes">
    <div class="header-container">
      <img v-if="showPhoneHeader" :src="logo" @click="handleLogoClick" />
      <p v-html="linkbarText" />
      <a :href="'tel:' + linkbarUrl">{{ linkbarUrl }}</a>
    </div>
  </div>
</template>

<script>
import logo from '../images/logo.svg';

export default {
  props: {
    showPhoneHeader: {
      type: Boolean,
      default: false,
    },
    showCountdownLinkbar: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const width =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;

    return {
      linkbarUrl:
        this.$tvData.phone_number || gon.phone_number || '000-000-0000',
      isSimpleTexting: this.$quizData.is_simple_texting,
      isMobile: width < 768,
      logo,
    };
  },
  computed: {
    linkbarText() {
      if (this.showPhoneHeader)
        return 'Call us today to schedule your free consultation';
      if (this.isSimpleTexting && this.isMobile)
        return 'Contact us today to get a free expert&nbsp;consultation';
      return 'Schedule today or call us to get a&nbsp;free&nbsp;consultation';
    },
    classes() {
      if (this.showPhoneHeader) {
        return 'linkbar-countdown-phone';
      }

      return 'quiz-linkbar-v1';
    },
  },
  mounted() {
    if (this.isSimpleTexting) {
      window.addEventListener('resize', this.detectIfMobile);
    }
  },
  methods: {
    handleLogoClick() {
      this.$scrollTo('#form-top', 2000, {
        offset: -100,
      });
    },
    detectIfMobile() {
      const width =
        window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth;

      if (width < 768 && !this.isMobile) {
        this.isMobile = true;
      } else if (width >= 768 && this.isMobile) {
        this.isMobile = false;
      }

      return null;
    },
  },
};
</script>
