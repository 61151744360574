<template>
  <div
    id="form-hero"
    class="form-hero"
  >
    <div class="container">
      <div class="hero-content">
        <div class="title-block">
          <h2
            v-if="content.title"
            v-html="content.title"
          />
          <p
            v-if="content.text"
            v-html="content.text"
          />
        </div>
      </div>
      <ListBlock
        v-if="content.listBlock"
        :content="content.listBlock"
      />
    </div>
    <ResultsForm
      :formTop="content.form"
      :path="path"
    />
  </div>
</template>

<script>
import ListBlock from './ListBlock.vue'
import ResultsForm from './ResultsForm.vue'

export default {
  components: {
    ListBlock,
    ResultsForm,
  },
  props: {
    content: {
      type: Object,
      default: () => {},
    },
    path: {
      type: String,
      default: '',
    },
  },
  mounted() {
    this.$store.dispatch('HERO_DATA', this.$quizData.listicle.hero)
  },
}
</script>
