var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'discount-carousel',
    { 'discount-carousel-v2': _vm.isV2 },
    { 'discount-carousel-v3': _vm.isV3 },
    { 'list-too-small': _vm.isV3 && _vm.offersLength < 4 }
  ],attrs:{"id":"discount-carousel"}},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"carousel-wrapper"},_vm._l((_vm.offers),function(offer,i){return _c('div',{key:`card-${i}`,staticClass:"card"},[(offer.icon)?_c('img',{staticClass:"icon",attrs:{"src":_vm.importImage(offer.icon),"alt":"Discount Icon"}}):_vm._e(),_vm._v(" "),(offer.headline)?_c('h3',{staticClass:"headline",domProps:{"innerHTML":_vm._s(offer.headline)}}):_vm._e(),_vm._v(" "),(offer.offer)?_c('h4',{staticClass:"title",domProps:{"innerHTML":_vm._s(offer.offer)}}):_vm._e(),_vm._v(" "),(offer.payment)?_c('h5',{staticClass:"payment",domProps:{"innerHTML":_vm._s(offer.payment)}}):_vm._e(),_vm._v(" "),(offer.expire_text)?_c('h6',{staticClass:"expire-text",domProps:{"innerHTML":_vm._s(offer.expire_text)}}):_vm._e(),_vm._v(" "),(offer.disclaimer)?_c('p',{staticClass:"disclaimer",domProps:{"innerHTML":_vm._s(offer.disclaimer)}}):_vm._e()])}),0),_vm._v(" "),(!_vm.isV2 && !_vm.isV3)?_c('div',{staticClass:"arrows-container"},[_c('img',{staticClass:"left-arrow",attrs:{"src":_vm.arrow}}),_vm._v(" "),_c('img',{staticClass:"right-arrow",attrs:{"src":_vm.arrow}})]):(!_vm.isV3)?_c('div',{staticClass:"card-counter"},[_c('p',{staticClass:"card-counter-text"},[_c('span',{attrs:{"id":"counter"}}),_vm._v("/"+_vm._s(_vm.offersLength)+"\n    ")])]):_vm._e(),_vm._v(" "),_c('small',{staticClass:"discount-disclaimer-small"},[_vm._v("*Interest is billed during promo period but will be waived if the amount financed is paid in full before promo period expires.")])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"discount-title-block"},[_c('h2',{staticClass:"title"},[_vm._v("\n      Limited Time Only! Take the"),_c('br',{staticClass:"br-small"}),_vm._v(" Quiz to Claim Your Offer.\n    ")]),_vm._v(" "),_c('p',{staticClass:"lead"},[_vm._v("\n      Swipe to see more!\n    ")])])
}]

export { render, staticRenderFns }