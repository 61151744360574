<template>
  <section
    :class="['sticky-quiz-top-v2', content.classes]"
  >
    <a
      v-if="content.linkbar_text && content.linkbar_link && !content.start && !content.end && !$quizData.show_timed_linkbar"
      v-scroll-to="{
        el: content.linkbar_link,
        offset: -150,
        duration: 500,
      }"
      data-trackable="banner"
      data-interaction="cta-click"
      class="sticky-linkbar"
      :href="content.linkbar_link"
      v-html="content.linkbar_text"
    />

    <LinkBarTimed
      v-if="content.start && content.end && $quizData.show_timed_linkbar"
      :endDate="getEndDateMemo()"
      :content="{}"
    />

    <header :class="content.header_link ? 'link-pointer sticky-header' : 'sticky-header'"
      v-scroll-to="{
        el: content.header_link || '',
        offset: -100,
        duration: 500,
      }"
    >
      <img
        v-if="content.logo_path"
        :src="content.logo_path"
        alt="RBA Logo"
        @click="handleLogoClick"
      >

      <a
        class="sticky-btn"
        data-trackable="general"
        data-interaction="phone-call"
        :href="'tel:' + phoneUrl"
        @click="handlePhoneClick"
      >
        Call Now<span class="gc-number">&nbsp;{{ phoneUrl }}</span>
      </a>
    </header>
  </section>
</template>

<script>
const LinkBarTimed = () => import('../components/LinkBarTimed.vue')

export default {
  components: {
    LinkBarTimed
  },
  props: {
    content: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      phoneUrl: this.$tvData.phone_number || gon.phone_number || '000-000-0000',
    }
  },
  methods: {
    getEndDateMemo() {
      if (this.$quizData.show_timed_linkbar && this.content.end) {
        return this.$moment(new Date(this.content.end))
      }

      return this.$moment()
    },
    handleLogoClick() {
      this.$scrollTo('#form-top', 2000, {
        offset: -100,
      })
    },
    handlePhoneClick() {
      if (typeof fbq !== 'undefined') {
        fbq('track', 'Contact')
      }
      _paq.push(['trackEvent', 'general-cta', 'phone-call'])
    },
  },
}
</script>
