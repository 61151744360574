<template>
  <div id="sd-container">
    <div class="arrow" />
    <div class="arrow" />
  </div>
</template>
<script>
export default {
  name: 'ScrollDown',
}
</script>
