<template>
  <div
    id="discount-carousel"
    :class="[
      'discount-carousel',
      { 'discount-carousel-v2': isV2 },
      { 'discount-carousel-v3': isV3 },
      { 'list-too-small': isV3 && offersLength < 4 }
    ]"
  >
    <div class="discount-title-block">
      <h2 class="title">
        Limited Time Only! Take the<br class="br-small"> Quiz to Claim Your&nbsp;Offer.
      </h2>
      <p class="lead">
        Swipe to see more!
      </p>
    </div>

    <div class="carousel-wrapper">
      <div
        v-for="(offer, i) in offers"
        :key="`card-${i}`"
        class="card"
      >
        <img
          v-if="offer.icon"
          class="icon"
          :src="importImage(offer.icon)"
          alt="Discount Icon"
        >
        <h3
          v-if="offer.headline"
          class="headline"
          v-html="offer.headline"
        />
        <h4
          v-if="offer.offer"
          class="title"
          v-html="offer.offer"
        />
        <h5
          v-if="offer.payment"
          class="payment"
          v-html="offer.payment"
        />
        <h6
          v-if="offer.expire_text"
          class="expire-text"
          v-html="offer.expire_text"
        />
        <p
          v-if="offer.disclaimer"
          class="disclaimer"
          v-html="offer.disclaimer"
        />
      </div>
    </div>

    <div
      v-if="!isV2 && !isV3"
      class="arrows-container"
    >
      <img
        class="left-arrow"
        :src="arrow"
      >
      <img
        class="right-arrow"
        :src="arrow"
      >
    </div>
    <div
      v-else-if="!isV3"
      class="card-counter"
    >
      <p class="card-counter-text">
        <span id="counter" />/{{ offersLength }}
      </p>
    </div>
    <small class='discount-disclaimer-small'>*Interest is billed during promo period but will be waived if the amount financed is paid in full before promo period expires.</small>
  </div>
</template>

<script>
import arrow from '../images/carousel-arrow.svg'
import data from '../data/discountCarousel.json'

export default {
  props: {
    isV2: {
      type: Boolean,
      default: false,
    },
    isV3: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const offerKey = this.$quizData.discount
    const offer = offerKey ? data[offerKey] : data.default
    let offers = offer
    if (this.isV2) {
      offer.unshift({
        payment: 'No interest, no money down, no payments for<span class="year-text">1 Year!</span>',
      })
    } else {
      offer.unshift({
        payment: 'No interest, no money down, no payments for 1 year!',
      })
    }

    if (!this.isV2 && !this.isV3) {
      offers = offer.concat(offer)
    }

    return {
      offersLength: offers.length,
      offers,
      arrow,
      currentIndex: 0,
    }
  },
  mounted() {
    if (this.isV2) {
      $('.carousel-wrapper').slick({
        mobileFirst: true,
        arrows: true,
        dots: false,
        infinite: true,
        centerMode: false,
        responsive: [
          {
            breakpoint: 1279,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 319,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      })

      document.getElementById('counter').innerText = 1

      $('.carousel-wrapper').on('afterChange', function(event, slick, currentSlide, nextSlide) {
        const counter = document.getElementById('counter')
        counter.innerText = (currentSlide + 1)
      })
    } else if (this.isV3) {
      const listTooSmall = this.offersLength < 4
      const settingsTB = listTooSmall
        ? 'unslick'
        : { slidesToShow: 3, centerMode: false }
      const settingsDT = listTooSmall
        ? 'unslick'
        : { slidesToShow: 3, centerMode: true }

      $('.carousel-wrapper').slick({
        mobileFirst: true,
        arrows: false,
        dots: true,
        infinite: true,
        centerMode: true,
        responsive: [
          {
            breakpoint: 1279,
            settings: settingsDT,
          },
          {
            breakpoint: 767,
            settings: settingsTB,
          },
          {
            breakpoint: 319,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      })
    } else {
      $('.carousel-wrapper').slick({
        mobileFirst: true,
        arrows: true,
        dots: false,
        infinite: true,
        centerMode: true,
        prevArrow: $('.arrows-container .left-arrow'),
        nextArrow: $('.arrows-container .right-arrow'),
        responsive: [
          {
            breakpoint: 1279,
            settings: {
              slidesToShow: 3,
              centerMode: true,
            },
          },
          {
            breakpoint: 767,
            settings: {
              slidesToShow: 3,
              centerMode: false,
            },
          },
          {
            breakpoint: 319,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      })
    }
  },
  methods: {
    importImage(src) {
      return require(`../images/discount-callout/${src}`)
    },
  },
}
</script>
