<template>
  <div class="quiz-bottom">
    <ResultsContainer
      v-if="!isListicle && !isFormResults && !quizData.resultsCallout && !quizData.formInHero"
      v-observe-visibility="visibilityChanged"
      :path="path"
    />
    <div
      v-if="quizData.coldWeatherCallout || quizData.coldWeatherDetails"
      class="cold-weather-wrapper"
    >
      <h2>Stay Comfortable No <br class="d-sm-down" />Matter the Season</h2>
      <ListCallout
        v-if="quizData.coldWeatherCallout"
        :content="quizData.coldWeatherCallout"
      />
      <DetailsCarousel
        v-if="quizData.coldWeatherDetails"
        :content="quizData.coldWeatherDetails"
      />
    </div>
    <ResultsCallout
      v-if="quizData.resultsCallout"
      :content="quizData.resultsCallout"
    />
    <ProjectsCarousel
      v-if="quizData.projectsCarousel"
      :content="quizData.projectsCarousel"
    />
    <ShowcaseCarousel
      v-if="quizData.showcaseCarousel"
      :content="quizData.showcaseCarousel"
    />
    <ListCallout
      v-if="quizData.whatsNext"
      :content="quizData.whatsNext"
    />
    <ListCallout
      v-if="quizData.listCallout"
      :content="quizData.listCallout"
    />
    <DetailsCarousel
      v-if="quizData.detailsCarousel"
      :content="quizData.detailsCarousel"
    />
    <Comparison
      v-if="quizData.comparison"
      :content="quizData.comparison"
    />
    <ImageSection
      v-if="quizData.productsCarousel"
      :content="quizData.productsCarousel"
    />
    <SplitImageSection
      v-if="quizData.split_image_section"
      :content="quizData.split_image_section"
    />
    <ReviewsSection
      v-if="quizData.show_reviews"
      class="stacked"
      :carousel="isListicle"
    />
    <FeaturesCarousel
      v-if="quizData.details_carousel"
      :content="quizData.details_carousel"
    />
    <FeaturedReviewsCarousel
      v-if="quizData.featuredReviewsCarousel"
      :content="quizData.featuredReviewsCarousel"
    />
    <ImageSection
      v-if="quizData.hasBadgeSection"
      :content="imageSectionContent['badge-section']"
      class="badge-section"
    />
    <ImageSection
      v-if="showBeforeAndAfter"
      :content="imageSectionContent['before-after-section']"
      class="before-after-quiz"
    />
    <DetailsCarousel
      v-if="quizData.beforeAfterCarousel"
      :content="quizData.beforeAfterCarousel"
    />
    <DetailsCarouselv2
      v-if="quizData.beforeAfterCarouselv2"
      :content="quizData.beforeAfterCarouselv2"
    />
    <DetailsShowHide
      v-if="quizData.detailsShowHide"
      :content="quizData.detailsShowHide"
    />
    <CarouselShowHide
      v-if="quizData.carouselShowHide"
      :content="quizData.carouselShowHide"
    />
    <SeeMoreSection
      v-if="quizData.seeMoreSection"
      :content="quizData.seeMoreSection"
    />
    <HIWGrid
      v-if="quizData.hiw_grid"
      :content="quizData.hiw_grid"
    />
    <HowItWorks v-if="quizData.show_how_it_works" />
    <QuoteCallout
      v-if="quizData.quoteCallout"
      :content="quizData.quoteCallout"
    />
    <FinalCallout
      v-if="quizData.finalCallout"
      :content="quizData.finalCallout"
    />
    <StoryText
      v-if="quizData.storyText"
      :content="quizData.storyText"
    />
    <DisclaimerText v-else />
    <QuizFooter
      v-if="quizData.footer && !quizData.ignore_duplicate_footer"
      :content="quizData.footer"
    />
  </div>
</template>

<script>
import DisclaimerText from '../components/DisclaimerText.vue'
import imageSectionContent from '../data/imageSection.json'

const ResultsContainer = () => import('../components/ResultsContainer.vue')
const ResultsCallout = () => import('../components/ResultsCallout.vue')
const ProjectsCarousel = () => import('../components/ProjectsCarousel.vue')
const ShowcaseCarousel = () => import('../components/ShowcaseCarousel.vue')
const ListCallout = () => import('../components/ListCallout.vue')
const DetailsCarousel = () => import('../components/DetailsCarousel.vue')
const DetailsCarouselv2 = () => import('../components/DetailsCarouselv2.vue')
const DetailsShowHide = () => import('../components/DetailsShowHide.vue')
const CarouselShowHide = () => import('../components/CarouselShowHide.vue')
const SeeMoreSection = () => import('../components/SeeMoreSection.vue')
const Comparison = () => import('../components/Comparison.vue')
const ImageSection = () => import('../components/ImageSection.vue')
const SplitImageSection = () => import('../components/SplitImageSection.vue')
const ReviewsSection = () => import('../components/ReviewsSection.vue')
const FeaturesCarousel = () => import('../components/FeaturesCarousel.vue')
const FeaturedReviewsCarousel = () => import('../components/FeaturedReviewsCarousel.vue')
const HIWGrid = () => import('../components/HIWGrid.vue')
const HowItWorks = () => import('../components/HowItWorks.vue')
const QuoteCallout = () => import('../components/QuoteCallout.vue')
const FinalCallout = () => import('../components/FinalCallout.vue')
const StoryText = () => import('../components/StoryText.vue')
const QuizFooter = () => import('../components/QuizFooter.vue')

export default {
  components: {
    DisclaimerText,
    ResultsContainer,
    ResultsCallout,
    ProjectsCarousel,
    ShowcaseCarousel,
    ListCallout,
    DetailsCarousel,
    DetailsCarouselv2,
    DetailsShowHide,
    CarouselShowHide,
    SeeMoreSection,
    Comparison,
    ImageSection,
    SplitImageSection,
    ReviewsSection,
    FeaturesCarousel,
    FeaturedReviewsCarousel,
    HIWGrid,
    HowItWorks,
    QuoteCallout,
    FinalCallout,
    StoryText,
    QuizFooter,
  },
  props: {
    path: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      quizData: this.$quizData,
      isListicle: !!this.$quizData.listicle,
      isFormResults: this.$quizData.is_multi_page_embed && this.$quizData.is_form_results,
      showBeforeAndAfter: this.$quizData.show_before_after,
      imageSectionContent,
    }
  },
  methods: {
    visibilityChanged(isVisible) {
      // this.scrolledPastQuestions = isVisible
      this.$emit('scrolledPastQuestions', isVisible)
    },
  },
}
</script>
