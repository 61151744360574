<template>
  <div class="fine-print-container">
    <p
      class="fine-print"
      v-html="disclaimer"
    />
    <template v-if="showSecondDisclaimer">
      <br>
      <p
        v-if="hasExtraLine || isEsler"
        class="fine-print second-disclaimer"
        v-html="`**It is the only warranty among top selling window companies that meets all of the following requirements: easy to understand terms, unrestricted transferability, installation coverage, labor coverage, geographically unrestricted, coverage for exterior color, insect screens and hardware, and no maintenance requirement. Visit renewalbyandersen/nationsbest for&nbsp;details.<br><br><b>***Renewal by Andersen is the full-service replacement window division of Andersen. Source: 2022 Andersen Brand Survey of U.S. Homeowners</b>`"
      />
      <p
        v-else
        class="fine-print second-disclaimer"
        v-html="`**It is the only warranty among top selling window companies that meets all of the following requirements: easy to understand terms, unrestricted transferability, installation coverage, labor coverage, geographically unrestricted, coverage for exterior color, insect screens and hardware, and no maintenance requirement. Visit renewalbyandersen/nationsbest for&nbsp;details.`"
      />
    </template>
    <template v-if="showThirdDisclaimer">
      <br>
        <p
          class="fine-print third-disclaimer"
          v-html="`<b>***Renewal by Andersen is the full-service replacement window division of Andersen. Source: 2022 Andersen Brand Survey of U.S. Homeowners</b>`"
        />
    </template>
  </div>
</template>
<script>
export default {
  data() {
    return {
      disclaimer: this.$quizData.disclaimer,
      showSecondDisclaimer: this.$quizData.show_second_disclaimer,
      showThirdDisclaimer: this.$quizData.show_third_disclaimer || false,
      isEsler: window.location.pathname.includes('/esler'),
      hasExtraLine: false,
    }
  },
  computed: {
    updatedPath() {
      const path = window.location.pathname.replace(/_stg\/\d+\//, '')
      return path
    },
  },
  mounted() {
    if ([
      '/richmond/search',
      '/richmond/social',
      '/corp/canada/social',
      '/corp/gen/nextdoor',
      '/corp/national/nextdoor',
      '/corp/social',
      '/corp/tt',
      '/esler/search',
    ].includes(this.updatedPath) || this.updatedPath.includes('/moore')) {
      this.hasExtraLine = true
    }

    if (this.updatedPath === '/esler/search') {
      this.showSecondDisclaimer = false
      this.showThirdDisclaimer = true
    }
  },
}

</script>
