/* eslint-disable max-len */
/* eslint-disable no-return-assign */
/* eslint-disable no-param-reassign */
export default {
  MUTATE_INITIALIZE_STORE: (state, payload) => state.quizData = { ...state.quizData, ...payload },
  MUTATE_ADVANCE_QUIZ: (state, payload) => {
    state.advanceQuiz.currentStep++
    if (payload) {
      state.advanceQuiz.goToBranch = payload
    } else {
      state.advanceQuiz.goToBranch = null
    }
  },
  MUTATE_SET_FULLREVEALED: state => state.fullRevealed = !state.fullRevealed,
  MUTATE_QUIZ_STARTED: state => state.quizStarted = true,
  MUTATE_QUIZ_COMPLETED: state => {
    if (state.quizData.is_multi_page_embed && !state.quizData.is_form_results) {
      // Allows results form to see quiz is complete but doesn't advance to loader/results
      // Moving currentStep back 1 prevents error where nonexistant question is loaded
      state.advanceQuiz.currentStep--
      state.onePageValid = true
    } else {
      state.quizCompleted = true
    }
  },
  MUTATE_SET_LOADED: state => state.loaded = true,
  MUTATE_ONE_PAGE_VALID: state => state.onePageValid = true,
  MUTATE_HERO_DATA: (state, payload) => state.heroData = payload,
  MUTATE_APPOINTMENT_DATA: (state, payload) => state.appointmentData = payload,
}
