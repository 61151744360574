<template>
  <div :class="['split-hero', content.classes]">
    <div class="container">
      <MediaBlock
        :content="content"
      />

      <div class="hero-title-block">
        <h1
          v-if="content.title"
          class="hero-title"
          v-html="content.title"
        />

        <p
          v-if="content.text"
          class="lead"
          v-html="content.text"
        />

        <div
          v-if="content.ctaText && content.ctaLink"
          class="cta-block"
        >
          <div class="cta-wrapper">
            <a
              v-scroll-to="{
                el: content.ctaLink,
                offset: content.offset ? parseInt(content.offset) : -100,
                duration: 500,
              }"
              href="#"
              class="btn"
              @click.prevent="handleAnchorClick"
              v-html="content.ctaText"
            />
          </div>
        </div>

        <div
          v-if="content.badges"
          class="badge-container badges-desktop"
        >
          <img
            v-for="c, i in content.badges"
            :key="i"
            :class="['badge', 'badge-'+i]"
            :src="c.image"
          >
        </div>
      </div>

      <div
        v-if="content.badges"
        class="badge-container"
      >
        <img
          v-for="c, i in content.badges"
          :key="i"
          :class="['badge', 'badge-'+i]"
          :src="c.image"
        >
      </div>
    </div>
  </div>
</template>

<script>
import MediaBlock from './MediaBlock.vue'

export default {
  components: {
    MediaBlock,
  },
  props: {
    content: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    handleAnchorClick() {
      return window.rba.tracking.trackAnchorClick(this.content.tracking || 'hero-cta', this.content.interaction || 'cta-click')
    },
  },
}
</script>
