<template>
  <div
    id="discount-stacked"
    class="discount-stacked"
  >
    <TitleBlock
      v-if="$quizData.stacked_title"
      :title="$quizData.stacked_title"
      :text="expiration"
    />
    <TitleBlock
      v-else
      title="OFFER EXTENDED! Take the Quiz to Claim Your&nbsp;Offer."
      :text="expiration"
    />

    <div
      :class="['cards-container', { 'show-all': showAll }]"
    >
      <div
        v-for="(card, idx) in cards"
        :key="idx"
        :class="['card', { 'card-highlight': idx === 0 }]"
      >
        <h3
          v-if="card.headline"
          class="headline"
          v-html="card.headline"
        />

        <div
          class="offer-icon-pair"
        >
          <img
            v-if="card.icon"
            :src="importImage(card.icon)"
            alt="Offer icon"
          >

          <h4
            v-if="card.offer"
            class="offer-text"
            v-html="card.offer"
          />
        </div>

        <h5
          v-if="card.expiration"
          class="expiration"
          v-html="card.expiration"
        />
        <p
          v-if="card.disclaimer"
          class="disclaimer"
          v-html="card.disclaimer"
        />
      </div>
    </div>

    <button
      v-if="overflowLength"
      class="show-all-btn"
      type="button"
      @click="toggleShowAll"
    >
      {{ showAll ? 'Hide Offer' : 'Show full offer' }}
    </button>
    <small class='discount-disclaimer-small'>*Interest is billed during promo period but will be waived if the amount financed is paid in full before promo period expires.</small>
  </div>
</template>

<script>
/* eslint-disable */
import TitleBlock from './TitleBlock.vue'

export default {
  components: {
    TitleBlock,
  },
  props: {
    offers: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    const cards = []
    let discount_data
    if (window.location.pathname.includes('/mw')){
      discount_data = this.$quizData.dynamic_discount[0]
    }
    else{
      discount_data = this.offers
    }
    discount_data.titles.forEach(t => {
      cards.push(t)
    })

    if (discount_data.text) cards.unshift({ offer: discount_data.text })

    cards.push({
      expiration: discount_data.expiration,
      disclaimer: discount_data.disclaimer,
    })

    return {
      expiration: discount_data.expiration,
      cards,
      showAll: false,
    }
  },
  computed: {
    overflowLength() {
      return this.cards.length > 3
    },
  },
  methods: {
    importImage(src) {
      return require(`../images/discount-callout/${src}`)
    },
    toggleShowAll() {
      this.showAll = !this.showAll
    },
  },
}
</script>
