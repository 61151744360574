<template>
  <div style="display: contents;">
    <section
      :class="['split-hero-v4', content.classes]"
      id="quiz-start-bottom"
    >
      <!-- <ResultsForm
        :heroFormTitle="content.heroFormTitle"
        :formTop="this.$quizData.formTop"
        :path="path"
        :noQuiz="true"
      /> -->
      <div class="thesis-form-container">
        <DiscountBanner
          v-if="discountBanner"
          :stapleTitle="this.$quizData.discountSection.stapleTitle"
        />
        <h1 class="hero-title">Request a Free Design Consultation</h1>
        <ThesisForm>
          <template #loaderView>
            <!-- Add component for in-quiz form results-->
            <ResultsForm
              :heroFormTitle="content.heroFormTitle"
              :formTop="formTop"
              :path="path"
            />
          </template>
        </ThesisForm>
      </div>
      <div class="image-content">
        <img
          v-if="content.dynamicImage"
          class="dynamic-image"
          rel="preload"
          :src="content.dynamicImage"
        >
      </div>
    </section>

  </div>
</template>

<script>
import TitleBlock from '../components/TitleBlock.vue'
import DiscountBanner from '../components/DiscountBanner.vue'
import ResultsForm from '../components/ResultsForm.vue'
import InQuizFormResults from '../components/InQuizFormResults.vue'

export default {
  components: {
    TitleBlock,
    DiscountBanner,
    ResultsForm,
    InQuizFormResults
  },
  props: {
    path: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      content: this.$quizData.splitHeroIntrov4,
      phoneUrl: this.$tvData.phone_number || gon.phone_number || '000-000-0000',
      showDiscount: !!this.$quizData.discount,
      discountCallout: !!this.$quizData.showDiscountCallout,
      discountTicker: !!this.$quizData.showDiscountTicker,
      discountCarousel: !!this.$quizData.showDiscountCarousel,
      discountCarouselv2: !!this.$quizData.showDiscountCarouselv2,
      discountCards: !!this.$quizData.showDiscountCards,
      discountBanner: !!this.$quizData.discountSection.showBanner,
      formTop: this.$quizData.formTop
    }
  },
  mounted() {
    const booking = document.getElementsByClassName('booking-cta')
    booking.forEach(btn => {
      btn.addEventListener('click', () => {
        _paq.push(['trackEvent', 'general-cta', 'phone-call'])
      })
    })

    for (let i = 0; i < booking.length; i++) {
      booking[i].href = `tel:${this.phoneUrl}`
      booking[i].innerHTML += `<span style='display: none;'>${this.phoneUrl}</span>`
    }
  },
}
</script>
